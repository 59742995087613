// this is the movie page to load the most popular movies
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MeetingList from '../MeetingList';
import * as actions from '../../actions/feedbackActions';
import * as filterActions from '../../actions/filterActions';
import '../../styles/meeting-page.scss';
import FilterBar from './FilterBar';
import FilterPage from './FilterPage';
import { reload } from '../../utils/reloader';
import history from '../../store/history';

/* eslint no-console:off */

class MeetingPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasMoreItems: true,
		};
		if (this.props.filter.startDate) {
			this.props.filterActions.resetGlobalFilters(['startDate', 'endDate', 'tag', 'tagId']);
			reload();
		}
	}

	componentDidUpdate() {
		if (this.props.meetings && this.state.hasMoreItems) {
			this.setState({ hasMoreItems: false });
		}
	}

	loadItems = () => {
		if (this.state.hasMoreItems) {
			this.props.actions.loadMeetings();
		}
	}

	setMeetingFilter = (tagId, tag, startDate, endDate) => {
		this.props.filterActions.setGlobalFilters({ tagId, tag, startDate, endDate });

		reload();
		history.push('/');
	}

	deselectLeaderboard = () => {
		this.props.actions.deselectLeaderboard();
	}

	selectMeeting = (meeting) => {
		this.props.actions.selectMeeting(meeting);
	}

	render() {
		return (
			<Container id="pageContainer">
				<Row>
					<Col xs={8}>
						<Container className="page-inner-container">
							<FilterBar right="none" />
							<Row className="meetings-list-box">
								<MeetingList
									meetings={this.props.meetings}
									hasMoreItems={this.state.hasMoreItems}
									loadItems={this.loadItems}
									setMeetingFilter={this.setMeetingFilter}
									deselectLeaderboard={this.deselectLeaderboard}
									selectMeeting={this.selectMeeting}
								/>
							</Row>
						</Container>
					</Col>
					<Col xs={4}>
						<FilterPage />
					</Col>
				</Row>
			</Container>
		);
	}
}

MeetingPage.propTypes = {
	meetings: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	filterActions: PropTypes.object.isRequired,
	filter: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		meetings: state.feedback.meetings,
		filter: state.filter,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		filterActions: bindActionCreators(filterActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingPage);
