import React from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { logout } from '../../utils/tokenHelper';
import UserImage from '../UserImage';
import * as userActions from '../../actions/userActions';
import images from '../../constants/images';

/* global TERMS_URL PRIVACY_URL CONTACT_URL */

// eslint-disable-next-line react/prefer-stateless-function
class TopNav extends React.Component {
	render() {
		const { user } = this.props;
		return (
			<Container fluid="true" className="navbar-outer-box">
				<Container>
					<Navbar expand="lg">
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="mr-auto">
								<NavLink exact activeClassName="activeNav" to="/" onClick={() => this.props.userActions.deselectUser()}>HOME</NavLink>
								<NavLink exact activeClassName="activeNav" to="/give" onClick={() => this.props.userActions.deselectUser()}>GIVE</NavLink>
								<NavLink exact activeClassName="activeNav" to="/meetings" onClick={() => this.props.userActions.deselectUser()}>MEETINGS</NavLink>
								<NavLink exact activeClassName="activeNav" to="/profile" onClick={() => this.props.userActions.deselectUser()}>MY PROFILE</NavLink>
							</Nav>
							<Nav className="justify-content-end">
								<div className="user-image-box">
									<UserImage
										imageUrl={user.ImageUrl}
										firstName={user.PreferredName}
										lastName={user.LastName}
									/>
								</div>
								<NavDropdown title={user.PreferredName} id="basic-nav-dropdown">
									<NavDropdown.Item target="_blank" className="drop-down-item" href={TERMS_URL}>Terms of Use</NavDropdown.Item>
									<NavDropdown.Item target="_blank" className="drop-down-item" href={PRIVACY_URL}>Privacy Policy</NavDropdown.Item>
									<NavDropdown.Item target="_blank" className="drop-down-item" href={CONTACT_URL}>Contact Us</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item className="drop-down-item" onClick={() => logout()}>Logout</NavDropdown.Item>
								</NavDropdown>
								<Navbar.Brand>
									<img
										src={images.plogo}
										width="61"
										className="d-inline-block align-top nav-logo"
										alt="Perks at Work Logo"
									/>
								</Navbar.Brand>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
				</Container>
			</Container>
		);
	}
}

TopNav.propTypes = {
	user: PropTypes.object,
	userActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		user: state.user.self,
		router: state.router,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		userActions: bindActionCreators(userActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
