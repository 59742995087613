// this is the give page which loads the list of users to give feedback to
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import UserList from '../UserList';
import FilterBar from './FilterBar';
import FilterPage from './FilterPage';
import '../../styles/user-page.scss';
import * as actions from '../../actions/userActions';
import history from '../../store/history';

/* eslint no-console:off */

class UsersPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasMoreItems: true,
		};
	}

	componentDidUpdate() {
		const hasMoreUsersToLoad = this.props.users && this.props.userCount > this.props.users.length;
		if (hasMoreUsersToLoad && !this.state.hasMoreItems) {
			this.setState({ hasMoreItems: true });
		}
	}

	loadItems = () => {
		const noMoreUserToLoad
			= this.props.userCount > 0 && this.props.userCount <= this.props.users.length;
		const userNotFound = this.props.notFound;
		if (noMoreUserToLoad || userNotFound) {
			this.setState({ hasMoreItems: false });
			return;
		}

		this.props.actions.loadUsers(this.props.users.length);
	}

	selectUser = (user) => {
		if (user.UserId === this.props.self.UserId) {
			history.push('/profile');
		} else {
			this.props.actions.selectUser(user);
			history.push('/user');
		}
	}

	render() {
		return (
			<Container id="pageContainer">
				<Row>
					<Col xs={8}>
						<Container className="page-inner-container">
							<FilterBar right="search" searchFor="user" />
							<Row className="user-list-box">
								<UserList
									users={this.props.users}
									hasMoreItems={this.state.hasMoreItems}
									loadItems={this.loadItems}
									notFound={this.props.notFound}
									selectUser={this.selectUser}
								/>
							</Row>
						</Container>
					</Col>
					<Col xs={4}>
						<FilterPage />
					</Col>
				</Row>
			</Container>
		);
	}
}

UsersPage.propTypes = {
	users: PropTypes.array.isRequired,
	actions: PropTypes.object.isRequired,
	userCount: PropTypes.number.isRequired,
	notFound: PropTypes.bool.isRequired,
	self: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		users: state.user.users,
		userCount: state.user.userCount,
		notFound: state.user.notFound,
		self: state.user.self,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
