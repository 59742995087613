/* eslint-disable camelcase */
// helper functions for token
import {
	getAuthProvider,
	getIDToken,
	removeIDToken,
	removeToken,
	setPKCECodeVerifier,
	setState,
} from './localStorage';
import { generateRandomString, pkceChallengeFromVerifier } from './oidcHelper';

const jwtDecode = require('jwt-decode');

/* global 
	FEEDBACK_API 
	CLOUD_AUTH_URL 
	CLOUD_AUTH_ENDPOINT 
	CLOUD_AUTH_CLIENT_ID 
	CLOUD_REDIRECT_URL 
	CLOUD_LOGOUT_ENDPOINT */

/* eslint no-console:off */

const authHost = FEEDBACK_API;
const ping = '/stream';

const headers = (token) => ({
	'Content-Type': 'application/json',
	Authorization: `Bearer ${token}`,
});

/* global AUTH_URL AUTH_LOGOUT */

export const validateToken = (token) => {
	const url = authHost + ping;
	return fetch(url, {
		method: 'GET',
		headers: headers(token),
	}).then(() => true).catch(() => false);
};

export const getUserFromToken = (token) => jwtDecode(token);

export const logout = () => {
	// logout from feedback web
	removeToken();

	// logout from universal auth
	// redirect to login page
	const origin = window.location.origin;
	if (getAuthProvider() === 'PAW') window.location.assign(`${AUTH_URL}${AUTH_LOGOUT}?redirect_url=${origin}/`);
	if (getAuthProvider() === 'CLOUD') window.location.assign(`${CLOUD_AUTH_URL}${CLOUD_LOGOUT_ENDPOINT}?id_token_hint=${getIDToken()}&post_logout_redirect_uri=${origin}`);
	removeIDToken();
};

export const renewToken = async () => {
	let url;
	// Create and store a random "state" value
	const state = generateRandomString();
	setState(state);

	// Create and store a new PKCE code_verifier (the plaintext random secret)
	const code_verifier = generateRandomString();
	setPKCECodeVerifier(code_verifier);

	// Hash and base64-urlencode the secret to use as the challenge
	const code_challenge = await pkceChallengeFromVerifier(code_verifier);

	if (getAuthProvider() === 'CLOUD') {
		url = `${CLOUD_AUTH_URL + CLOUD_AUTH_ENDPOINT}?client_id=${CLOUD_AUTH_CLIENT_ID
		}&response_type=code&scope=openid feedback`
			+ `&redirect_uri=${CLOUD_REDIRECT_URL
			}&state=${state
			}&code_challenge=${encodeURIComponent(code_challenge)
			}&code_challenge_method=S256`
			+ '&prompt=none';
	}

	window.location.assign(url);
};

export const login = async () => {
	let url;
	// Create and store a random "state" value
	const state = generateRandomString();
	setState(state);

	// Create and store a new PKCE code_verifier (the plaintext random secret)
	const code_verifier = generateRandomString();
	setPKCECodeVerifier(code_verifier);

	// Hash and base64-urlencode the secret to use as the challenge
	const code_challenge = await pkceChallengeFromVerifier(code_verifier);

	if (getAuthProvider() === 'CLOUD') {
		url = `${CLOUD_AUTH_URL + CLOUD_AUTH_ENDPOINT}?client_id=${CLOUD_AUTH_CLIENT_ID
		}&response_type=code&scope=openid feedback`
			+ `&redirect_uri=${CLOUD_REDIRECT_URL
			}&state=${state
			}&code_challenge=${encodeURIComponent(code_challenge)
			}&code_challenge_method=S256`;
	}

	window.location.assign(url);
};
