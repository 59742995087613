export default {
	movies: [],

	user: {
		userCount: 0,
		users: [],
		user: {},
		self: {},
		notFound: false,
		metrics: {
			totalFeedback: 0,
			expectationScore: 0,
			rated1: 0,
			rated2: 0,
			rated3: 0,
			rated4: 0,
		},
		feedbackReceived: {
			feedback: [],
			msg: '',
		},
		feedbackCount: 0,
		filter: {
			team: '',
			sort: '',
			startDate: '',
			endDate: '',
			tagId: '',
			tag: '',
		},
		selectedUser: {
			user: {},
			metrics: {
				totalFeedback: 0,
				expectationScore: 0,
				rated1: 0,
				rated2: 0,
				rated3: 0,
				rated4: 0,
			},
			feedbackReceived: {
				feedback: [],
				msg: '',
			},
			feedbackCount: 0,
			filter: {
				team: '',
				sort: '',
				startDate: '',
				endDate: '',
				tagId: '',
				tag: '',
			},
		},
	},

	feedback: {
		streamCount: 0,
		stream: [],
		meetings: {},
		leaderboard: [],
		teams: [],
		tags: [],
		recommended: {
			orgRecent: {},
			orgPopular: {},
			userRecent: [],
		},
		metrics: {
			totalFeedback: 0,
			expectationScore: 0,
		},
		postFeedback: {
			done: false,
			msg: '',
		},
		postVote: {
			done: false,
			msg: '',
		},
		postFlag: {
			done: false,
			msg: '',
		},
		selectedMeeting: {},
	},

	filter: {
		team: '',
		sort: '',
		startDate: '',
		endDate: '',
		tagId: '',
		tag: '',
	},
};
