import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import images from '../constants/images';
import LeaderboardModal from './containers/LeaderboardModal';
import SingleMeetingTile from './SingleMeetingTile';

/* eslint no-console:off */

class MeetingList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tracks: [],
			showModal: false,
			selectedMeeting: null,
			selectedDay: '',
		};
	}

	showLeaderboardModal = (selectedMeeting, selectedDay) => {
		this.setState({
			showModal: true,
			selectedMeeting,
			selectedDay,
		});
	}

	showFeedbackForMeeting = (m) => {
		this.props.setMeetingFilter(m.TagUuid, m.Title, m.StartDatetime, m.EndDatetime);
		this.props.selectMeeting(m);
	}

	lgClose = () => {
		this.setState({
			showModal: false,
			selectedMeeting: null,
			selectedDay: '',
		});
		this.props.deselectLeaderboard();
	}

	render() {
		const meetings = this.props.meetings;
		const loader = <div key={0} className="loader">Loading ...</div>;
		let cols = [];
		// eslint-disable-next-line no-restricted-syntax
		for (const key in meetings) {
			// eslint-disable-next-line no-prototype-builtins
			if (meetings.hasOwnProperty(key)) {
				const dateItem = (
					<Row key={key} className="date-item-row">
						<Col xs={9} className="date-main-box">
							{(moment(key).format('ddd, D MMMM, YYYY')).toUpperCase()}
						</Col>
						<Col xs={3} className="date-button-box">
							<div className="button-wrapper">
								<Row onClick={() => this.showLeaderboardModal(null, key)}>
									<img src={images.snapshotIcon} className="link-icons" alt="" />
									<span>FULL SNAPSHOT</span>
								</Row>
							</div>
						</Col>
					</Row>
				);

				const items = meetings[key].map((m, index) => (
					<SingleMeetingTile
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						m={m}
						index={index}
						showLeaderboardModal={this.showLeaderboardModal}
						showFeedbackForMeeting={this.showFeedbackForMeeting}
					/>
				));
				cols = [...cols, dateItem, items];
			}
		}

		return (
			<Col>
				<Container className="page-inner-container">
					<InfiniteScroll
						pageStart={0}
						loadMore={this.props.loadItems}
						hasMore={this.props.hasMoreItems}
						loader={loader}
					>
						{cols}
					</InfiniteScroll>

					<LeaderboardModal
						selectedMeeting={this.state.selectedMeeting}
						selectedDay={this.state.selectedDay}
						showModal={this.state.showModal}
						showLeaderboardModal={this.showLeaderboardModal}
						lgClose={this.lgClose}
					/>
				</Container>
			</Col>
		);
	}
}

MeetingList.propTypes = {
	meetings: PropTypes.object.isRequired,
	hasMoreItems: PropTypes.bool.isRequired,
	loadItems: PropTypes.func.isRequired,
	setMeetingFilter: PropTypes.func.isRequired,
	deselectLeaderboard: PropTypes.func.isRequired,
	selectMeeting: PropTypes.func.isRequired,
};

export default MeetingList;

