import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
// "routerMiddleware": the new way of storing route changes with redux middleware since rrV4.
import { connectRouter, routerMiddleware } from 'connected-react-router';

import logger from '../middlewares/logger';
import authToken from '../middlewares/token';
import rootReducer from '../reducers';
import history from './history';

const connectRouterHistory = connectRouter(history);

function configureStoreProd(initialState) {
	const reactRouterMiddleware = routerMiddleware(history);
	const middlewares = [
		// Add other middleware on this line...
		authToken,

		// thunk middleware can also accept an extra argument to be passed to each thunk action
		// https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
		thunk,
		reactRouterMiddleware,
	];

	return createStore(
		rootReducer,
		initialState,
		compose(applyMiddleware(...middlewares)),
	);
}

function configureStoreDev(initialState) {
	const reactRouterMiddleware = routerMiddleware(history);
	const middlewares = [
		// Add other middleware on this line...
		logger,
		authToken,

		// Redux middleware that spits an error on you when you try to 
		// mutate your state either inside a dispatch or between dispatches.
		reduxImmutableStateInvariant(),

		// thunk middleware can also accept an extra argument to be passed to each thunk action
		// https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
		thunk,
		reactRouterMiddleware,
	];

	// add support for Redux dev tools
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const store = createStore(
		rootReducer,
		initialState,
		composeEnhancers(applyMiddleware(...middlewares)),
	);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('../reducers', () => {
			const nextRootReducer = require('../reducers').default; // eslint-disable-line global-require
			store.replaceReducer(connectRouterHistory(nextRootReducer));
		});
	}

	return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;

export const store = configureStore();
