// this is the movie page to load the most popular movies
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import images from '../../constants/images';
import { login } from '../../utils/tokenHelper';
import { setAuthProvider } from '../../utils/localStorage';

/* global TERMS_URL PRIVACY_URL  */

class LoginPage extends React.Component {
	loginCloud = () => {
		setAuthProvider('CLOUD');
		login();
	}

	render() {
		return (
			<Container className="login-container" fluid="true">
				<Row>
					<Col className="login-left-column">
						<img src={images.fbLogoLight} className="fb-bg-logo" alt="" />
						<Container className="login-left-container">
							<img src={images.loginPhone} className="login-left-image" alt="" />
						</Container>
					</Col>
					<Col className="login-right-column">
						<Container className="login-right-container">
							<Row>
								<img src={images.fbLogo} className="fb-logo-image" alt="" />
							</Row>
							<Row>
								<Button variant="primary" onClick={this.loginCloud}>LOG IN WITH PERKS AT WORK</Button>
							</Row>
							<Row>
								<Button variant="outline-primary" onClick={this.loginCloud}>FORGOT PASSWORD</Button>
							</Row>
							<Row className="get-the-app">
								get the app
							</Row>
							<Row className="store-img-row">
								<img src={images.appstore} className="app-logo-image" alt="" />
								<img src={images.googleplay} className="google-logo-image" alt="" />
							</Row>
							<Row className="privacy-policy-row">
								<img src={images.pawLogo} className="paw-logo-image" alt="" />
								<span className="privacy-policy-text">our <a href={TERMS_URL}>terms of use</a> & <a href={PRIVACY_URL}>privacy policy</a></span>
							</Row>
						</Container>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default LoginPage;
