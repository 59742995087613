// this is the movie page to load the most popular movies
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StreamList from '../StreamList';
import ProfileFilterBar from './ProfileFilterBar';
import UserBar from '../UserBar';
import * as actions from '../../actions/feedbackActions';
import * as userActions from '../../actions/userActions';
import '../../styles/profile-page.scss';
import FlagModal from './FlagModal';

/* eslint no-console:off */

class SelectedUserPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasMoreItems: true,
			showModal: false,
			selectedFeedback: null,
			successMsg: '',
			failureMsg: '',
			noFeedback: false,
		};
	}

	componentDidUpdate() {
		if (this.props.feedbackCount > this.props.feedback.length && !this.state.hasMoreItems) {
			this.setState({ hasMoreItems: true });
		}
	}

	showSuccessMsg = (msg) => {
		this.setState({ successMsg: msg }, () => {
			setTimeout(() => {
				this.setState({ successMsg: '' });
			}, 2000);
		});
	}

	showFailureMsg = (msg) => {
		this.setState({ failureMsg: msg }, () => {
			setTimeout(() => {
				this.setState({ failureMsg: '' });
			}, 2000);
		});
	}

	lgClose = () => {
		this.setState({
			showModal: false,
			selectedFeedback: null,
		});
	}

	loadItems = async (page, isReload = false) => {
		let offset = this.props.feedback.length;
		if (isReload) {
			offset = 0;
		}

		if (this.props.feedbackCount > 0 && this.props.feedbackCount <= offset) {
			this.setState({ hasMoreItems: false });
			return;
		}
		if (this.props.user.UserId) {
			await this.props.userActions.loadUserFeedback(0, true);
			if (this.props.feedback.length === 0) {
				this.setState({ noFeedback: true });
			} else {
				this.setState({ noFeedback: false });
			}
		}
	}

	renderFeedback = () => {
		if (this.props.feedbackErr) {
			return (
				<Row>
					<Col className="profile-feedback-error">
						{this.props.feedbackErr}
					</Col>
				</Row>
			);
		}

		if (this.state.noFeedback) {
			return (
				<Row>
					<Col className="profile-feedback-error">
						No feedback found!
					</Col>

				</Row>
			);
		}

		return (
			<Row>
				<StreamList
					feedback={this.props.feedback}
					hasMoreItems={this.state.hasMoreItems}
					loadItems={this.loadItems}
					vote={this.vote}
					renderFlag={this.renderFlag}
					filter={this.props.filter}
					isHomeStream={false}
				/>
			</Row>
		);
	}

	vote = (feedback, vote, callback) => {
		let responseId = '';
		let voteVal = vote;
		if (feedback.ResponseId) {
			responseId = feedback.ResponseId;
			if (feedback.CurrentUserVote === vote) {
				voteVal = 0;
			}
		} else {
			responseId = feedback.Response.ResponseId;
			if (feedback.Thumbs.UserThumb === vote) {
				voteVal = 0;
			}
		}

		this.props.actions.postVote(responseId, voteVal).then(() => {
			callback();
		});
	}

	renderFlag = (feedback) => {
		let currentUserFlag = 0;

		if (feedback.CurrentUserFlag) {
			currentUserFlag = feedback.CurrentUserFlag;
		} else {
			currentUserFlag = feedback.Flags.CurrentUserFlag;
		}

		if (currentUserFlag === 0) {
			this.setState({ showModal: true, selectedFeedback: feedback });
		} else {
			this.showFailureMsg('You have already flagged this feedback.');
		}
	}

	render() {
		return (
			<Container id="pageContainer">
				<Row>
					<Col xs={8}>
						<Container className="page-inner-container">
							<ProfileFilterBar right="sort" selectedUser user={this.props.user} filter={this.props.filter} />
							<div className={`success-msg ${this.state.successMsg.length > 0 ? 'success-msg-show' : 'success-msg-hidden'}`}>{this.state.successMsg}</div>
							<div className={`failure-msg ${this.state.failureMsg.length > 0 ? 'failure-msg-show' : 'failure-msg-hidden'}`}>{this.state.failureMsg}</div>
							{this.renderFeedback()}
						</Container>
					</Col>
					<Col xs={4}>
						<UserBar user={this.props.user} metrics={this.props.metrics} />
					</Col>
				</Row>
				<FlagModal
					selectedFeedback={this.state.selectedFeedback}
					showModal={this.state.showModal}
					showSuccessMsg={this.showSuccessMsg}
					showFailureMsg={this.showFailureMsg}
					lgClose={this.lgClose}
					flag={this.flag}
				/>
			</Container>
		);
	}
}

SelectedUserPage.propTypes = {
	feedback: PropTypes.array.isRequired,
	feedbackErr: PropTypes.string.isRequired,
	feedbackCount: PropTypes.number.isRequired,
	actions: PropTypes.object.isRequired,
	userActions: PropTypes.object.isRequired,
	filter: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	metrics: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		feedback: state.user.selectedUser.feedbackReceived.feedback,
		feedbackErr: state.user.selectedUser.feedbackReceived.msg,
		feedbackCount: state.user.selectedUser.feedbackCount,
		filter: state.user.selectedUser.filter,
		user: state.user.selectedUser.user,
		metrics: state.user.selectedUser.metrics,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		userActions: bindActionCreators(userActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedUserPage);
