/* eslint-disable func-names */
import moment from 'moment';
import FeedbackApi from '../api/feedbackApi';
import { store } from '../store/configureStore';

/* eslint no-console:off */

const dateFormat = 'YYYY-MM-DD';

const loadUsersSuccess = (users, meta) => ({
	type: 'LOAD_USERS_SUCCESS',
	meta,
	users,
});

const reloadUsersSuccess = (users, meta) => ({
	type: 'RELOAD_USERS_SUCCESS',
	meta,
	users,
});

const loadUserByIdSuccess = (users) => ({
	type: 'LOAD_USER_BY_ID_SUCCESS',
	user: users[0],
});

const loadSelfSuccess = (self) => ({
	type: 'LOAD_SELF_SUCCESS',
	self,
});

const loadSelfMetricsSuccess = (metrics) => ({
	type: 'LOAD_SELF_METRICS_SUCCESS',
	metrics,
});

const loadSelfMetricsFailure = () => ({
	type: 'LOAD_SELF_METRICS_FAILURE',
});

const loadUserMetricsSuccess = (metrics) => ({
	type: 'LOAD_USER_METRICS_SUCCESS',
	metrics,
});

const loadUserMetricsFailure = () => ({
	type: 'LOAD_USER_METRICS_FAILURE',
});

const loadSelfFeedbackSuccess = (feedback) => ({
	type: 'LOAD_SELF_FEEDBACK_SUCCESS',
	feedback,
});

const loadSelfFeedbackFailure = () => ({
	type: 'LOAD_SELF_FEEDBACK_FAILURE',
});

const loadUserFeedbackSuccess = (feedback) => ({
	type: 'LOAD_USER_FEEDBACK_SUCCESS',
	feedback,
});

const loadUserFeedbackFailure = () => ({
	type: 'LOAD_USER_FEEDBACK_FAILURE',
});

export function resetSelfFilters(toReset = []) {
	if (toReset.length === 0) {
		return {
			type: 'RESET_SELF_FILTERS_ALL',
		};
	}

	return {
		type: 'RESET_SELF_FILTERS_PARTIAL',
		toReset,
	};
}

export function resetUserFilters(toReset = []) {
	if (toReset.length === 0) {
		return {
			type: 'RESET_USER_FILTERS_ALL',
		};
	}

	return {
		type: 'RESET_USER_FILTERS_PARTIAL',
		toReset,
	};
}

const selectUserSuccess = (user) => ({
	type: 'SELECT_USER_SUCCESS',
	user,
});

const deselectUserSuccess = () => ({
	type: 'DESELECT_USER_SUCCESS',
});

// example of calling api using redux-thunk middleware
export function loadUsers(offset, isReload, q = '') {
	return function (dispatch) {
		// apply global filters here
		// retrieve from store
		let fq = '';
		const s = store.getState();
		const { team } = s.filter;
		if (team && team.length > 0) {
			fq = `Team:${team}`;
		}

		return FeedbackApi.getUsers(offset, q, fq).then((resp) => {
			if (isReload) {
				dispatch(reloadUsersSuccess(resp.Data.users, resp.Data.metadata));
				return;
			}

			dispatch(loadUsersSuccess(resp.Data.users, resp.Data.metadata));
		}).catch((error) => {
			throw (error);
		});
	};
}

export function loadUserById(id) {
	const fq = `UserId:${id}`;

	return function (dispatch) {
		return FeedbackApi.getUsers(0, '', fq).then((resp) => {
			dispatch(loadUserByIdSuccess(resp.Data.users));
		}).catch((error) => {
			throw (error);
		});
	};
}

export function loadUserMetrics(user) {
	const end = moment().add(1, 'days');
	let start = moment('2009-01-01');
	if (user.HireDate) {
		start = moment(user.HireDate);
	}
	const userId = user.UserId;
	const selfId = store.getState().user.self.UserId;

	return function (dispatch) {
		return FeedbackApi.getUserMetrics(
			start.format(dateFormat),
			end.format(dateFormat),
			userId,
		).then((resp) => {
			if (userId === selfId) {
				if ('OnesReceived' in resp.Data) {
					dispatch(loadSelfMetricsSuccess(resp.Data));
					return resp.Data;
				}
				dispatch(loadSelfMetricsFailure());
			} else {
				if ('OnesReceived' in resp.Data) {
					dispatch(loadUserMetricsSuccess(resp.Data));
					return resp.Data;
				}
				dispatch(loadUserMetricsFailure());
			}
		}).catch((error) => {
			throw (error);
		});
	};
}

export function loadSelf() {
	return function (dispatch) {
		return FeedbackApi.getSelf().then((resp) => {
			dispatch(loadSelfSuccess(resp.Data));
			dispatch(loadUserMetrics(resp.Data));
		}).catch((error) => {
			throw (error);
		});
	};
}

export function loadUserFeedback(offset, selectedUser = false) {
	const [limit, filters] = [50, {}];

	// apply global filters here
	// retrieve from store
	let stateUser = store.getState().user;
	let userId = stateUser.self.UserId;
	let isSelf = true;
	if (selectedUser) {
		stateUser = store.getState().user.selectedUser;
		userId = stateUser.user.UserId;
		isSelf = false;
	}
	const { team, sort, startDate, endDate, tagId } = stateUser.filter;
	if (team && team.length > 0) {
		filters.team = team;
	}
	if (sort && sort.length > 0) {
		filters.sort = sort;
	}
	if (startDate && startDate.length > 0) {
		filters.startDate = startDate;
	}
	if (endDate && endDate.length > 0) {
		filters.endDate = endDate;
	}
	if (tagId && tagId.length > 0) {
		filters.tagId = tagId;
	}

	return function (dispatch) {
		return FeedbackApi.getUserFeedback(userId, offset, limit, filters).then((resp) => {
			if (isSelf) {
				if (resp.Data && (resp.Data.length >= 0)) {
					dispatch(loadSelfFeedbackSuccess(resp.Data));
					return resp.Data;
				}
				dispatch(loadSelfFeedbackFailure());
			} else {
				if (resp.Data && (resp.Data.length >= 0)) {
					dispatch(loadUserFeedbackSuccess(resp.Data));
					return resp.Data;
				}
				dispatch(loadUserFeedbackFailure());
			}
		}).catch((error) => {
			throw (error);
		});
	};
}

export function setUserFilters(filters = {}, user = {}) {
	const userFilters = {};

	if (filters.length === 0) {
		return {
			type: 'NO_USER_FILTER_FOUND',
		};
	}

	const { team, sort, startDate, endDate, tag, tagId } = filters;
	if (team && team.length > 0) {
		userFilters.team = team;
	}

	if (tagId && tagId.length > 0) {
		userFilters.tagId = tagId;
		userFilters.tag = tag;
	}

	if (startDate && startDate.length > 0) {
		// TODO: need to check date format
		userFilters.startDate = startDate;
	}

	if (endDate && endDate.length > 0) {
		// endDate has to be later than startDate at least by a day
		const start = moment(startDate);
		let end = moment(endDate);
		if (end <= start) {
			end = start.add(1, 'day');
		}
		userFilters.endDate = end.format(dateFormat);
	}

	if (sort && sort.length > 0) {
		userFilters.sort = sort;

		// make sure the date range is set for sorting that's not by time
		// hard code for v1
		if (!userFilters.startDate && !userFilters.endDate) {
			const end = moment().add(1, 'days');
			const start = moment().add(-7, 'days');
			userFilters.startDate = start.format(dateFormat);
			userFilters.endDate = end.format(dateFormat);
		}
	}

	if (user.UserId) {
		return {
			type: 'SET_USER_FILTERS_SUCCESS',
			userFilters,
		};
	}

	return {
		type: 'SET_SELF_FILTERS_SUCCESS',
		userFilters,
	};
}

export function selectUser(user) {
	return function (dispatch) {
		dispatch(selectUserSuccess(user));
		dispatch(loadUserMetrics(user));
		dispatch(loadUserFeedback(0, true));
	};
}

export function deselectUser() {
	return function (dispatch) {
		dispatch(deselectUserSuccess());
	};
}
