import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import user from './userReducer';
import feedback from './feedbackReducer';
import filter from './filterReducer';
import history from '../store/history';

const rootReducer = combineReducers({
	router: connectRouter(history),
	user,
	feedback,
	filter,
});

export default rootReducer;
