// this is the reducer for all feedback related actions
import { produce } from 'immer';
import moment from 'moment';
import {
	LOAD_FEEDBACK_STREAM_SUCCESS,
	RELOAD_FEEDBACK_STREAM_SUCCESS,
	LOAD_MEETINGS_SUCCESS,
	RELOAD_MEETINGS_SUCCESS,
	LOAD_LEADERBOARD_STREAM_SUCCESS,
	LOAD_DAY_LEADERBOARD_SUCCESS,
	LOAD_TEAMS_SUCCESS,
	LOAD_METRICS_SUCCESS,
	LOAD_METRICS_FAILURE,
	LOAD_TAGS_SUCCESS,
	LOAD_RECOMMENDED_TAGS_SUCCESS,
	POST_FEEDBACK_SUCCESS,
	POST_FEEDBACK_FAILURE,
	POST_VOTE_SUCCESS,
	POST_VOTE_FAILURE,
	POST_FLAG_SUCCESS,
	POST_FLAG_FAILURE,
	DESELECT_LEADERBOARD_SUCCESS,
	SELECT_MEETING_SUCCESS,
	DESELECT_MEETING_SUCCESS,
} from '../constants/actionTypes';
import initialState from './initialState';

const compare = (a, b) => {
	const nameA = a.User.FullName;
	const nameB = b.User.FullName;
	const rankA = a.Rank;
	const rankB = b.Rank;

	if (rankA < rankB) {
		return -1;
	} if (rankA > rankB) {
		return 1;
	}
	if (nameA > nameB) {
		return 1;
	} if (nameA < nameB) {
		return -1;
	}
	return 0;
};

/* eslint no-console:off */

const feedbackReducer = (state = initialState.feedback, action) =>
	produce(state, (draft) => {
		switch (action.type) {
		case LOAD_FEEDBACK_STREAM_SUCCESS:
			if (action.stream) {
				draft.stream = [...draft.stream, ...action.stream];
			}
			if (action.meta && action.meta.TotalCount) {
				draft.streamCount = action.meta.TotalCount;
			}
			return;

		case RELOAD_FEEDBACK_STREAM_SUCCESS:
			draft.stream = action.stream;
			if (action.meta && action.meta.TotalCount) {
				draft.streamCount = action.meta.TotalCount;
			}
			if (draft.stream == null) {
				draft.stream = [];
			}
			return;

		case RELOAD_MEETINGS_SUCCESS:
		case LOAD_MEETINGS_SUCCESS: {
			const meetingObj = {};
			// obj manipulation
			// eslint-disable-next-line array-callback-return
			action.meetings.map((value) => {
				const thisMeeting = value;
				if (thisMeeting.EventType === 'scheduled' && thisMeeting.StartAt && thisMeeting.EndAt) {
					const endDate = moment(thisMeeting.EndAt, 'YYYY-MM-DD HH:mm:ss.S Z');
					const startDate = moment(thisMeeting.StartAt, 'YYYY-MM-DD HH:mm:ss.S Z');
					const diffEnd = endDate.diff(moment(), 'minute');
					const diffStart = startDate.diff(moment(), 'minute');
					if (diffEnd >= -60 && diffStart <= 0) {
						thisMeeting.IsLive = true;
					}
				}
				if (!(thisMeeting.StartDatetime in meetingObj)) {
					meetingObj[thisMeeting.StartDatetime] = [thisMeeting];
					return;
				}

				meetingObj[thisMeeting.StartDatetime]
						= [...meetingObj[thisMeeting.StartDatetime], thisMeeting];
			});
			draft.meetings = meetingObj;
			return;
		}

		case LOAD_LEADERBOARD_STREAM_SUCCESS:
			action.leaderboard.sort(compare);
			draft.leaderboard = action.leaderboard;
			return;

		case LOAD_DAY_LEADERBOARD_SUCCESS:
			action.leaderboard.sort(compare);
			draft.leaderboard = action.leaderboard;
			return;

		case LOAD_TEAMS_SUCCESS:
			draft.teams = [...draft.teams, ...action.teams];
			return;

		case LOAD_METRICS_SUCCESS: {
			const { TotalRatings, Rated1, Rated2, Rated3, Rated4 } = action.metrics;
			draft.metrics.totalFeedback = TotalRatings;

			const avg = (Rated1 * 1 + Rated2 * 2 + Rated3 * 3 + Rated4 * 4) / TotalRatings;
			draft.metrics.expectationScore = avg;
			return;
		}

		case LOAD_METRICS_FAILURE:
			console.warn('failed in loading org level metrics');
			return;

		case LOAD_TAGS_SUCCESS:
			draft.tags = action.tags.TagsOfOrg;
			return;

		case LOAD_RECOMMENDED_TAGS_SUCCESS: {
			draft.recommended.orgRecent = action.recommended.MostRecentTagsForOrg[0];

			if (action.recommended.MostRecentTagsForOrg[0].TagId
					=== action.recommended.MostPopularTagsForOrg[0].TagId) {
				draft.recommended.orgPopular = action.recommended.MostPopularTagsForOrg[1];
			} else {
				draft.recommended.orgPopular = action.recommended.MostPopularTagsForOrg[0];
			}

			draft.recommended.userRecent = action.recommended.RecentTagsForUser.slice(0, 3);

			let userRecent = [];
			const orgRecentTag = draft.recommended.orgRecent.Tag;
			const orgPopularTag = draft.recommended.orgPopular.Tag;
			// eslint-disable-next-line array-callback-return
			action.recommended.RecentTagsForUser.map((value) => {
				if (value.Tag === orgRecentTag) { return; }
				if (value.Tag === orgPopularTag) { return; }
				if (userRecent.length === 3) { return; }

				userRecent = [...userRecent, value];
			});
			return;
		}

		case POST_FEEDBACK_SUCCESS:
			draft.postFeedback.done = true;
			return;

		case POST_FEEDBACK_FAILURE:
			draft.postFeedback.done = true;
			draft.postFeedback.msg = action.msg;
			return;

		case POST_VOTE_SUCCESS:
			draft.postVote.done = true;
			return;

		case POST_VOTE_FAILURE:
			draft.postVote.done = true;
			draft.postVote.msg = action.msg;
			return;
		case POST_FLAG_SUCCESS:
			draft.postFlag.done = true;
			return;

		case POST_FLAG_FAILURE:
			draft.postFlag.done = true;
			draft.postFlag.msg = action.msg;
			return;

		case DESELECT_LEADERBOARD_SUCCESS:
			draft.leaderboard = [];
			return;

		case SELECT_MEETING_SUCCESS:
			draft.selectedMeeting = action.meeting;
			return;

		case DESELECT_MEETING_SUCCESS:
			draft.selectedMeeting = {};
		}
	});

export default feedbackReducer;
