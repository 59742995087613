// this is the movie page to load the most popular movies
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import '../../styles/stream-page.scss';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { reload } from '../../utils/reloader';
import * as filterActions from '../../actions/filterActions';
import * as actions from '../../actions/feedbackActions';
import images from '../../constants/images';
import {
	ORG_UUID_NXJ,
	ORG_UUID_NAVY,
	ORG_NAME_PAW,
	ORG_NAME_NXJ,
	ORG_NAME_NAVY,
	ORG_NAME_WORLD,
} from '../../constants/orgTypes';

/* eslint no-console:off */

class FilterPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchTerm: '',
			filteredSearch: [],
		};
	}

	componentDidMount() {
		if (this.props.teams.length === 0) {
			this.props.actions.loadTeams(0, 1500);
		}

		if (this.props.metrics.totalFeedback === 0 ||
			this.props.metrics.expectationScore === 0) {
			this.props.actions.loadMetrics();
		}
	}

	renderTeams = () => {
		const teams = this.state.filteredSearch.map((value, index) => (
			<Form.Check
				// eslint-disable-next-line react/no-array-index-key
				key={index}
				type="radio"
				id={index}
				name="teamRadio"
				checked={this.props.filter.team === value.Value}
				value={value.Value}
				label={value.Value}
				onClick={this.checkTeamHandler}
				onChange={this.checkTeamHandler}
			/>
		));

		return teams;
	}

	resetFilter = (toReset = []) => {
		this.props.filterActions.resetGlobalFilters(toReset);
		reload();
	}

	renderAppliedFilters = () => {
		const filter = this.props.filter;
		let appliedFilter = [];
		if (filter.team) {
			appliedFilter = [...appliedFilter, { type: ['team'], value: filter.team }];
		} else {
			appliedFilter = [...appliedFilter, { type: [], value: 'All Teams' }];
		}

		if (filter.tag) {
			appliedFilter = [...appliedFilter, { type: ['tagId'], value: filter.tag }];
		}

		if (filter.startDate && filter.endDate) {
			const dateFilter = `${moment(filter.startDate).format('MMM D, YYYY')} - ${moment(filter.endDate).format('MMM D, YYYY')}`;
			appliedFilter = [...appliedFilter, { type: ['startDate', 'endDate'], value: dateFilter }];
		} else {
			appliedFilter = [...appliedFilter, { type: [], value: 'All Time' }];
		}

		const af = appliedFilter.map((value, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<div key={value + index} className="selected-filter">
				{value.value}
				<span className={value.type.length === 0 ? 'displayNone' : 'remove-filter'} onClick={() => this.resetFilter(value.type)}>
					<img src={images.crossIcon} alt="" />
				</span>
			</div>
		));
		return af;
	}

	checkTeamHandler = (event) => {
		if (event.target.checked) {
			this.props.filterActions.setGlobalFilters({ team: event.target.value });
			this.setState({ searchTerm: '' });
		} else {
			// reset filter
			this.props.filterActions.setGlobalFilters({ team: '' });
		}

		reload();
	}

	searchTeams = (event) => {
		// need to set fq for team actions
		const newData = this.props.teams.filter((value) => {
			const itemData = value.Value.toUpperCase();
			const textData = event.target.value.toUpperCase();
			return itemData.indexOf(textData) > -1;
		});

		this.setState({
			filteredSearch: newData,
			searchTerm: event.target.value,
		});
	}

	scoreTextColor = (expectationScore) => {
		let textColor = '';
		if (expectationScore < 1.7 || expectationScore > 2.5) {
			textColor = '#AF2419';
		} else if (expectationScore < 2.01 && expectationScore > 1.85) {
			textColor = '#3F5728';
		} else {
			textColor = '#7AA05E';
		}
		return textColor;
	}

	render() {
		let expectationScore = this.props.metrics.expectationScore;
		let totalFeedback = this.props.metrics.totalFeedback;
		if (totalFeedback >= 10000) {
			totalFeedback = '10000+';
		}
		const { OrgId, OrgName } = this.props.self;
		if (this.state.searchTerm === '' && this.props.teams && this.state.filteredSearch !== this.props.teams) {
			this.setState({ filteredSearch: this.props.teams });
		}

		expectationScore = expectationScore.toFixed(2);

		let orgName = ORG_NAME_PAW;
		let orgLogo = images.perksLogo;
		if (OrgId === ORG_UUID_NAVY) {
			orgName = ORG_NAME_NAVY; // hardcoding for now, but ideally move to get the orgname from API
			orgLogo = images.navyLogo;
		} else if (OrgId === ORG_UUID_NXJ) {
			orgName = ORG_NAME_NXJ;
			orgLogo = images.nxjLogo;
		} else if (OrgName === ORG_NAME_WORLD) {
			orgName = 'Perks at Work | WORLD';
		}

		return (
			<Container className="page-inner-container">
				<div className="right-company-box single-box">
					<div className="company-top-area" />
					<div className="company-name-section">
						{orgName}
					</div>
					<div className="company-numbers-section">
						<div className="participation-number">
							<div className="total-fb-number">{totalFeedback}</div>
							<div className="total-fb-title">TOTAL FEEDBACK</div>
						</div>
						<div className="expectation-number">
							<div className="total-expec-number" style={{ color: this.scoreTextColor(expectationScore) }}>{expectationScore}</div>
							<div className="total-expec-title">EXPECTATION</div>
						</div>
					</div>
					<div className="company-logo-box">
						<img src={orgLogo} className="company-logo" alt="" />
					</div>
					<Row className="time-period-text">
						<Col>PAST 30 DAYS STATS</Col>
					</Row>
				</div>
				<div className="right-filter-box single-box">
					<div className="filter-top-area">
						FILTER FEEDBACK
					</div>
					<Container className="filter-details-container">
						<Row className="filter-type-row">
							<div className="filter-type-title">APPLIED FILTERS</div>
							{this.renderAppliedFilters()}
						</Row>

						<Row className="team-list-row bottom">
							<div className="filter-type-title">BY TEAMS</div>
							<Form className="team-form">
								<Form.Group controlId="formTeamSearch" className="form-group-search">
									<Form.Control
										type="text"
										className="team-search-box"
										placeholder="Search Team By Name"
										value={this.state.searchTerm}
										onChange={this.searchTeams}
									/>
								</Form.Group>
								<Form.Group controlId="formTeamList">
									{this.renderTeams()}
								</Form.Group>
							</Form>
						</Row>

					</Container>
				</div>
			</Container>
		);
	}
}

FilterPage.propTypes = {
	teams: PropTypes.array.isRequired,
	metrics: PropTypes.object,
	actions: PropTypes.object.isRequired,
	filterActions: PropTypes.object.isRequired,
	filter: PropTypes.object.isRequired,
	self: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		teams: state.feedback.teams,
		metrics: state.feedback.metrics,
		filter: state.filter,
		self: state.user.self,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		filterActions: bindActionCreators(filterActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterPage);
