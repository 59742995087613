// this is reducer for page actions
import { produce } from 'immer';
import {
	SET_GLOBAL_FILTERS_SUCCESS,
	RESET_GLOBAL_FILTERS_ALL,
	RESET_GLOBAL_FILTERS_PARTIAL,
} from '../constants/actionTypes';
import initialState from './initialState';

const filterReducer = (state = initialState.filter, action) =>
	produce(state, (draft) => {
		switch (action.type) {
		case SET_GLOBAL_FILTERS_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const { team, sort, startDate, endDate, tagId, tag } = action.globalFilters;
			if (team) {
				draft.team = team;
			}
			if (sort) {
				draft.sort = sort;
			}

			if (startDate) {
				draft.startDate = startDate;
			}

			if (endDate) {
				draft.endDate = endDate;
			}

			if (tagId) {
				draft.tagId = tagId;
				draft.tag = tag;
			}
			return;

		case RESET_GLOBAL_FILTERS_ALL:
			draft.team = '';
			draft.sort = '';
			draft.startDate = '';
			draft.endDate = '';
			draft.tagId = '';
			draft.tag = '';
			return;

		case RESET_GLOBAL_FILTERS_PARTIAL:
			if (action.toReset.includes('team')) {
				draft.team = '';
			}

			if (action.toReset.includes('sort')) {
				draft.sort = '';
				draft.startDate = '';
				draft.endDate = '';
			}

			if (action.toReset.includes('startDate')) {
				draft.startDate = '';
			}

			if (action.toReset.includes('endDate')) {
				draft.endDate = '';
			}

			if (action.toReset.includes('tagId')) {
				draft.tagId = '';
				draft.tag = '';
			}
		}
	});

export default filterReducer;
