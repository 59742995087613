export function getToken() {
	return localStorage.getItem('auth-token');
}

export function setToken(value) {
	localStorage.setItem('auth-token', value);
}

export function removeToken() {
	localStorage.removeItem('auth-token');
}

export function getIDToken() {
	return localStorage.getItem('id-token');
}

export function setIDToken(value) {
	localStorage.setItem('id-token', value);
}

export function removeIDToken() {
	localStorage.removeItem('id-token');
}

export function getAuthCode() {
	return localStorage.getItem('auth-code');
}

export function setAuthCode(value) {
	localStorage.setItem('auth-code', value);
}

export function removeAuthCode() {
	localStorage.removeItem('auth-code');
}

export function getAuthProvider() {
	return localStorage.getItem('auth-provider');
}

export function setAuthProvider(value) {
	localStorage.setItem('auth-provider', value);
}

export function removeAuthProvider() {
	localStorage.removeItem('auth-provider');
}

export function getPKCECodeVerifier() {
	return localStorage.getItem('pkce_code_verifier');
}

export function setPKCECodeVerifier(value) {
	localStorage.setItem('pkce_code_verifier', value);
}

export function removePKCECodeVerifier() {
	localStorage.removeItem('pkce_code_verifier');
}

export function getState() {
	return localStorage.getItem('state');
}

export function setState(value) {
	localStorage.setItem('state', value);
}

export function removeState() {
	localStorage.removeItem('state');
}

export function getLatestUrl() {
	return localStorage.getItem('latest-url');
}

export function setLatestUrl(value) {
	localStorage.setItem('latest-url', value);
}

export function removeLatestUrl() {
	localStorage.removeItem('latest-url');
}
