import { loadFeedbackStream, loadMeetings } from '../actions/feedbackActions';
import { loadUsers, loadUserFeedback } from '../actions/userActions';
import { store } from '../store/configureStore';

export const reload = (ele = []) => {
	// stream
	if (ele.length === 0 || ele.includes('stream')) {
		store.dispatch(loadFeedbackStream(0, true));
	}

	// users
	if (ele.length === 0 || ele.includes('users')) {
		store.dispatch(loadUsers(0, true));
	}

	// meetings 
	if (ele.length === 0 || ele.includes('meetings')) {
		store.dispatch(loadMeetings(0, true));
	}

	if (ele.length === 0 || ele.includes('selfFeedback')) {
		store.dispatch(loadUserFeedback(0));
	}

	if (ele.length === 0 || ele.includes('userFeedback')) {
		store.dispatch(loadUserFeedback(0, true));
	}
};
