import React, { Component } from 'react';
import PropTypes from 'prop-types';
import images from '../constants/images';

class ProfileVoteButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			up: this.props.fb.Thumbs.Up,
			down: this.props.fb.Thumbs.Down,
			current: this.props.fb.Thumbs.UserThumb,
			currentUserFlag: this.props.fb.Flags.CurrentUserFlag,
			id: this.props.fb.Response.ResponseId,
		};
	}

	postVote = (voteType) => {
		// post vote handler
		let newUp = this.state.up;
		let newDown = this.state.down;
		let newCurrent = this.state.current;

		if (voteType === this.state.current) { // current user canceling their vote
			newCurrent = 0;
			if (this.state.current > 0) {
				newUp -= 1;
			} else {
				newDown -= 1;
			}
		} else if (this.state.current === 0) { // current user adding their vote
			newCurrent = voteType;
			if (newCurrent > 0) {
				newUp += 1;
			} else {
				newDown += 1;
			}
		} else { // current user changing their vote (up->down or down->up)
			newCurrent = voteType;
			if (newCurrent > 0) {
				newUp += 1;
				newDown -= 1;
			} else {
				newDown += 1;
				newUp -= 1;
			}
		}

		this.setState({
			current: newCurrent,
			up: newUp,
			down: newDown,
		});
	}

	render() {
		const { up, down, current, id } = this.state;
		const { index } = this.props;
		let { downvote, upvote, flag } = images;
		let upVoteSelected = '';
		let downVoteSelected = '';
		let flagSelected = '';

		if (current === 1) {
			upvote = images.upvoteon;
			upVoteSelected = 'active-vote-icon';
		} else if (current === -1) {
			downvote = images.downvoteon;
			downVoteSelected = 'active-vote-icon';
		}

		if (this.props.fb.Flags.CurrentUserFlag === 1) {
			flag = images.flagon;
			flagSelected = 'active-flag';
		}

		return (
			<div key={id + index}>
				<img
					src={upvote}
					className="upvoteIcon"
					onClick={() => this.props.vote(this.props.fb, 1, () => this.postVote(1))}
					alt=""
				/>
				<span className={`vote-icon-span ${upVoteSelected}`}>{up}</span>

				<img
					src={downvote}
					className="downvoteIcon"
					onClick={() => this.props.vote(this.props.fb, -1, () => this.postVote(-1))}
					alt=""
				/>
				<span className={`vote-icon-span ${downVoteSelected}`}>{down}</span>

				<img src={flag} className="flagIcon" onClick={() => this.props.renderFlag(this.props.fb)} alt="" />
				<span className={flagSelected}>{this.props.fb.Flags.InappropriateFlagCount}</span>
			</div>
		);
	}
}

ProfileVoteButton.propTypes = {
	fb: PropTypes.object.isRequired,
	index: PropTypes.number,
	vote: PropTypes.func.isRequired,
	renderFlag: PropTypes.func.isRequired,
};

export default ProfileVoteButton;

