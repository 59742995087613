import plogo from '../images/plogo.png';
import timeIcon from '../images/timeIcon.png';
import meetingIcon from '../images/meetingIcon.png';
import upvote from '../images/upvote.png';
import downvote from '../images/downvote.png';
import flag from '../images/flag.png';
import upvoteon from '../images/upvoteSelected.png';
import downvoteon from '../images/downvoteSelected.png';
import flagon from '../images/flagon.png';
import upvoteBig from '../images/upvoteBig.png';
import downvoteBig from '../images/downvoteBig.png';
import flagBig from '../images/flagBig.png';
import timeIconBig from '../images/timeIconBig1.png';
import upvoteBigOn from '../images/upvoteBigSelected.png';
import downvoteBigOn from '../images/downvoteBigSelected.png';
import flagBigOn from '../images/flagBigSelected.png';
import timeIconBigOn from '../images/timeIconBigSelected.png';
import meetingTagIcon from '../images/meetingTagIcon.png';
import meetingTagDark from '../images/meetingTagDark.png';
import feedbackIcon from '../images/feedbackIcon.png';
import snapshotIcon from '../images/snapshotIcon.png';
import companyCircle from '../images/companyCircle.png';
import nxjLogo from '../images/nxjlogo.png';
import navyLogo from '../images/navylogo.png';
import perksLogo from '../images/perkslogo.png';
import loginPhone from '../images/loginPhoneImage.png';
import fbLogo from '../images/fbLogo.png';
import pawLogo from '../images/pawLogo.png';
import appstore from '../images/appstore.png';
import googleplay from '../images/googleplay.png';
import fbLogoLight from '../images/fbLogoLight.png';
import backButton from '../images/backButton.png';
import singleLine from '../images/singleLine.png';
import crossIcon from '../images/crossIcon.png';
import favIconBig from '../images/faviconBig.ico';

export default {
	plogo,
	timeIcon,
	meetingIcon,
	upvote,
	downvote,
	flag,
	upvoteon,
	downvoteon,
	flagon,
	upvoteBig,
	downvoteBig,
	flagBig,
	timeIconBig,
	upvoteBigOn,
	downvoteBigOn,
	flagBigOn,
	timeIconBigOn,
	meetingTagIcon,
	meetingTagDark,
	feedbackIcon,
	snapshotIcon,
	companyCircle,
	nxjLogo,
	navyLogo,
	perksLogo,
	loginPhone,
	fbLogo,
	pawLogo,
	appstore,
	googleplay,
	fbLogoLight,
	backButton,
	singleLine,
	crossIcon,
	favIconBig,
};
