import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import UserImage from './UserImage';

class SingleUserCard extends Component {
	render() {
		const { user, index } = this.props;

		const teamList = [];
		const teamLimit = 1;
		if (user.Team) {
			// eslint-disable-next-line array-callback-return
			user.Team.map((teamName, i) => {
				if (i > teamLimit) {
					return;
				}
				teamList.push(
					<span key={user.UserId + teamName} className="team-span">{teamName}</span>,
				);
				if (i < teamLimit && user.Team.length > teamLimit) {
					teamList.push(
						<span key={user.UserId} className="team-middot">&middot;</span>,
					);
				}
			});
		}

		return (
			<Card key={user.UserId + index} onClick={() => this.props.showGiveModal(user)}>
				<Card.Body>
					<Col xs={3} className="user-image-box">
						<UserImage
							imageUrl={user.ImageUrl}
							firstName={user.PreferredName}
							lastName={user.LastName}
						/>
					</Col>
					<Col xs={9} className="name-box">
						<Row className="name-box-title text-black">
							{user.FullName}
						</Row>
						<Row className="name-box-teams text-font text-light-grey">
							{teamList}
						</Row>
						<Row className="button-row">
							<Button variant="outline-primary">GIVE FEEDBACK</Button>
							<Button variant="outline-primary" onClick={() => this.props.selectUser(user)}>SEE PROFILE</Button>
						</Row>
					</Col>
				</Card.Body>
			</Card>
		);
	}
}

SingleUserCard.propTypes = {
	user: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	showGiveModal: PropTypes.func,
	selectUser: PropTypes.func.isRequired,
};

export default SingleUserCard;
