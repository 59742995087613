// this is the movie page to load the most popular movies
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StreamList from '../StreamList';
import FilterBar from './FilterBar';
import FilterPage from './FilterPage';
import * as actions from '../../actions/feedbackActions';
import * as userActions from '../../actions/userActions';
import '../../styles/stream-page.scss';
import FlagModal from './FlagModal';
import history from '../../store/history';

/* eslint no-console:off */

class StreamPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasMoreItems: true,
			showModal: false,
			selectedFeedback: null,
			successMsg: '',
			failureMsg: '',
		};
	}

	componentDidUpdate() {
		if (this.props.feedbackCount > this.props.feedback.length && !this.state.hasMoreItems) {
			this.setState({ hasMoreItems: true });
		}
	}

	showSuccessMsg = (msg) => {
		this.setState({ successMsg: msg }, () => {
			setTimeout(() => {
				this.setState({ successMsg: '' });
			}, 2000);
		});
	}

	showFailureMsg = (msg) => {
		this.setState({ failureMsg: msg }, () => {
			setTimeout(() => {
				this.setState({ failureMsg: '' });
			}, 2000);
		});
	}

	lgClose = () => {
		this.setState({
			showModal: false,
			selectedFeedback: null,
		});
	}

	loadItems = (page, isReload = false) => {
		let offset = this.props.feedback.length;
		if (isReload) {
			offset = 0;
		}

		if (this.props.feedbackCount > 0 && this.props.feedbackCount <= offset) {
			this.setState({ hasMoreItems: false });
			return;
		}
		if (this.props.self.UserId) {
			this.props.actions.loadFeedbackStream(offset, isReload);
		}
	}

	vote = (feedback, vote, callback) => {
		let voteVal = vote;
		if (feedback.CurrentUserVote === vote) {
			voteVal = 0;
		}
		this.props.actions.postVote(feedback.ResponseId, voteVal).then(() => {
			callback();
		});
	}

	renderFlag = (feedback) => {
		if (feedback.CurrentUserFlag === 0) {
			this.setState({ showModal: true, selectedFeedback: feedback });
		} else {
			this.showFailureMsg('You have already flagged this feedback.');
		}
	}

	selectUser = (user) => {
		if (user.UserId === this.props.self.UserId) {
			history.push('/profile');
		} else {
			this.props.userActions.selectUser(user);
			history.push('/user');
		}
	}

	deselectMeeting = () => {
		this.props.actions.deselectMeeting();
	}

	render() {
		return (
			<Container id="pageContainer">
				<Row>
					<Col xs={8}>
						<Container className="page-inner-container">
							<FilterBar right="sort" />
							<div className={`success-msg ${this.state.successMsg.length > 0 ? 'success-msg-show' : 'success-msg-hidden'}`}>{this.state.successMsg}</div>
							<div className={`failure-msg ${this.state.failureMsg.length > 0 ? 'failure-msg-show' : 'failure-msg-hidden'}`}>{this.state.failureMsg}</div>
							<Row>
								<StreamList
									feedback={this.props.feedback}
									hasMoreItems={this.state.hasMoreItems}
									loadItems={this.loadItems}
									vote={this.vote}
									renderFlag={this.renderFlag}
									filter={this.props.filter}
									isHomeStream
									selectUser={this.selectUser}
									deselectMeeting={this.deselectMeeting}
								/>
							</Row>
						</Container>
					</Col>
					<Col xs={4}>
						<FilterPage />
					</Col>
				</Row>
				<FlagModal
					selectedFeedback={this.state.selectedFeedback}
					showModal={this.state.showModal}
					showSuccessMsg={this.showSuccessMsg}
					showFailureMsg={this.showFailureMsg}
					lgClose={this.lgClose}
					flag={this.flag}
				/>
			</Container>
		);
	}
}

StreamPage.propTypes = {
	feedback: PropTypes.array.isRequired,
	feedbackCount: PropTypes.number.isRequired,
	actions: PropTypes.object.isRequired,
	userActions: PropTypes.object.isRequired,
	filter: PropTypes.object.isRequired,
	self: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		feedback: state.feedback.stream,
		feedbackCount: state.feedback.streamCount,
		filter: state.filter,
		self: state.user.self,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		userActions: bindActionCreators(userActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamPage);
