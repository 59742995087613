import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import LoginPage from './containers/LoginPage';
import CallbackPage from './CallbackPage';

// eslint-disable-next-line react/prefer-stateless-function
export default class Root extends Component {
	render() {
		const { store, history } = this.props;
		return (
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<Switch>
						<Route exact path="/login" component={LoginPage} />
						<Route exact path="/paw/callback" component={CallbackPage} />
						<Route path="/" component={App} />
					</Switch>
				</ConnectedRouter>
			</Provider>
		);
	}
}

Root.propTypes = {
	store: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};
