/* eslint-disable func-names */
import moment from 'moment';
import FeedbackApi from '../api/feedbackApi';
import { store } from '../store/configureStore';

const ErrNoReceiver = 'Invalid request for posting feedback: Empty Feedback Receiver';
const ErrNoRating = 'Invalid request for posting feedback: Empty Feedback Rating';
const ErrNoComment = 'Invalid request for posting feedback: Empty Feedback Comment';
const ErrInvalidResponseId = 'Invalid request for posting vote: Empty Feedback Identifier';
const ErrInvalidVote = 'Invalid request for posting vote: Empty vote or Invalid vote type';

const loadFeedbackStreamSuccess = (stream, meta) => ({
	type: 'LOAD_FEEDBACK_STREAM_SUCCESS',
	stream,
	meta,
});

const reloadFeedbackStreamSuccess = (stream, meta) => ({
	type: 'RELOAD_FEEDBACK_STREAM_SUCCESS',
	stream,
	meta,
});

const loadMeetingsSuccess = (meetings) => ({
	type: 'LOAD_MEETINGS_SUCCESS',
	meetings,
});

const reloadMeetingsSuccess = (meetings) => ({
	type: 'RELOAD_MEETINGS_SUCCESS',
	meetings,
});

const loadLeaderboardSuccess = (leaderboard) => ({
	type: 'LOAD_LEADERBOARD_STREAM_SUCCESS',
	leaderboard,
});

const loadDayLeaderboardSuccess = (leaderboard) => ({
	type: 'LOAD_DAY_LEADERBOARD_SUCCESS',
	leaderboard,
});

const loadTeamsSuccess = (teams) => ({
	type: 'LOAD_TEAMS_SUCCESS',
	teams,
});

const loadMetricsSuccess = (metrics) => ({
	type: 'LOAD_METRICS_SUCCESS',
	metrics,
});

const loadMetricsFailure = () => ({
	type: 'LOAD_METRICS_FAILURE',
});

const loadTagsSuccess = (tags) => ({
	type: 'LOAD_TAGS_SUCCESS',
	tags,
});

const loadRecommendedTagsSuccess = (recommended) => ({
	type: 'LOAD_RECOMMENDED_TAGS_SUCCESS',
	recommended,
});

const postFeedbackSuccess = (msg) => ({
	type: 'POST_FEEDBACK_SUCCESS',
	msg,
});

const postFeedbackFailure = (msg) => ({
	type: 'POST_FEEDBACK_FAILURE',
	msg,
});

const postVoteSuccess = (msg) => ({
	type: 'POST_VOTE_SUCCESS',
	msg,
});

const postVoteFailure = (msg) => ({
	type: 'POST_VOTE_FAILURE',
	msg,
});

const postFlagSuccess = (msg) => ({
	type: 'POST_FLAG_SUCCESS',
	msg,
});

const postFlagFailure = (msg) => ({
	type: 'POST_FLAG_FAILURE',
	msg,
});

const deselectLeaderboardSuccess = () => ({
	type: 'DESELECT_LEADERBOARD_SUCCESS',
});

const deselectMeetingSuccess = () => ({
	type: 'DESELECT_MEETING_SUCCESS',
});

const selectMeetingSuccess = (meeting) => ({
	type: 'SELECT_MEETING_SUCCESS',
	meeting,
});

export function loadFeedbackStream(offset, isReload = false) {
	const [limit, filters] = [50, {}];

	// apply global filters here
	// retrieve from store
	const s = store.getState();
	const { team, sort, startDate, endDate, tagId } = s.filter;
	if (team && team.length > 0) {
		filters.team = team;
	}
	if (sort && sort.length > 0) {
		filters.sort = sort;
	}
	if (startDate && startDate.length > 0) {
		filters.startDate = startDate;
	}
	if (endDate && endDate.length > 0) {
		filters.endDate = endDate;
	}
	if (tagId && tagId.length > 0) {
		filters.tagId = tagId;
	}

	return function (dispatch) {
		return FeedbackApi.getStream(offset, limit, filters).then((resp) => {
			// add error handlers here
			if (isReload) {
				dispatch(reloadFeedbackStreamSuccess(resp.Data, resp.Metadata));
				return;
			}

			dispatch(loadFeedbackStreamSuccess(resp.Data, resp.Metadata));
		}).catch((error) => {
			throw (error);
		});
	};
}

export function loadMeetings(offset, isReload = false) {
	const filters = {};
	const s = store.getState();

	const { team, startDate, endDate } = s.filter;
	if (team && team.length > 0) {
		filters.team = team;
	}
	if (startDate && startDate.length > 0) {
		filters.startDate = startDate;
	}
	if (endDate && endDate.length > 0) {
		filters.endDate = endDate;
	}

	return function (dispatch) {
		return FeedbackApi.getMeetings(filters).then((resp) => {
			if (isReload) {
				dispatch(reloadMeetingsSuccess(resp.Data));
				return resp.Data;
			}

			dispatch(loadMeetingsSuccess(resp.Data));
		}).catch((error) => {
			throw (error);
		});
	};
}

export function loadLeaderboard(id, startDate, endDate, isLive) {
	return function (dispatch) {
		if (isLive) {
			return FeedbackApi.getLiveLeaderboard(id, startDate, endDate).then((resp) => {
				dispatch(loadLeaderboardSuccess(resp.Data));
			}).catch((error) => {
				throw (error);
			});
		}
		return FeedbackApi.getLeaderboard(id, startDate, endDate).then((resp) => {
			dispatch(loadLeaderboardSuccess(resp.Data));
		}).catch((error) => {
			throw (error);
		});
	};
}

export function loadDayLeaderboard(startDate, endDate) {
	return function (dispatch) {
		return FeedbackApi.getDayLeaderboard(startDate, endDate).then((resp) => {
			dispatch(loadDayLeaderboardSuccess(resp.Data));
		}).catch((error) => {
			throw (error);
		});
	};
}

export function loadTeams(offset = 0, limit = 20) {
	return function (dispatch) {
		return FeedbackApi.getTeams(offset, limit).then((resp) => {
			dispatch(loadTeamsSuccess(resp.Data.OrgTeams));
			return resp.Data.OrgTeams;
		}).catch((error) => {
			throw (error);
		});
	};
}

export function loadMetrics() {
	const end = moment().add(1, 'days');
	const start = moment().add(-30, 'days');

	return function (dispatch) {
		return FeedbackApi.getMetrics(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')).then((resp) => {
			if (Array.isArray(resp.Data) && resp.Data.length > 0) {
				dispatch(loadMetricsSuccess(resp.Data[0]));
				return resp.Data[0];
			}
			dispatch(loadMetricsFailure());
		}).catch((error) => {
			throw (error);
		});
	};
}

export function loadTags() {
	return function (dispatch) {
		return FeedbackApi.getTags().then((resp) => {
			dispatch(loadTagsSuccess(resp.Data));
		}).catch((error) => {
			throw (error);
		});
	};
}

export function loadRecommendedTags(userId) {
	return function (dispatch) {
		return FeedbackApi.getRecommendedTags(userId).then((resp) => {
			dispatch(loadRecommendedTagsSuccess(resp.Data));
		}).catch((error) => {
			throw (error);
		});
	};
}

export function postFeedback(recipientId, rating, comment, tags = []) {
	const payload = {
		recipientId,
		rating,
		comment,
		tags,
	};

	return function (dispatch) {
		if (!payload.recipientId) {
			return dispatch(postFeedbackFailure(ErrNoReceiver));
		}
		if (!payload.rating) {
			return dispatch(postFeedbackFailure(ErrNoRating));
		}
		if (!payload.comment) {
			return dispatch(postFeedbackFailure(ErrNoComment));
		}

		return FeedbackApi.postFeedback(payload).then((resp) => {
			// handle server side error
			if (resp.ResponseCode !== 200) {
				return resp.ErrorMessage;
			}

			dispatch(postFeedbackSuccess(resp.Data));
			return resp.Data;
		}).catch((error) => {
			throw (error);
		});
	};
}

export function postVote(responseId, vote) {
	const payload = {
		responseId,
		vote,
	};

	return function (dispatch) {
		if (!payload.responseId) {
			return dispatch(postVoteFailure(ErrInvalidResponseId));
		}

		if ((typeof payload.vote) !== 'number') {
			return dispatch(postVoteFailure(ErrInvalidVote));
		}

		return FeedbackApi.postVote(payload).then((resp) => {
			dispatch(postVoteSuccess(resp.Data.ErrorMessage));
			return resp;
		}).catch((error) => {
			throw (error);
		});
	};
}

export function postFlag(responseId, reason) {
	const payload = {
		responseId,
		reason,
	};

	return function (dispatch) {
		if (!payload.responseId) {
			dispatch(postFlagFailure(ErrInvalidResponseId));
			return ErrInvalidResponseId;
		}

		return FeedbackApi.postFlag(payload).then((resp) => {
			if (resp.length > 0) {
				dispatch(postFlagFailure(resp));
				return resp;
			}

			dispatch(postFlagSuccess(resp.Data));
		}).catch((error) => {
			throw (error);
		});
	};
}

export function deselectLeaderboard() {
	return function (dispatch) {
		dispatch(deselectLeaderboardSuccess());
	};
}

export function selectMeeting(meeting) {
	return function (dispatch) {
		dispatch(selectMeetingSuccess(meeting));
	};
}

export function deselectMeeting() {
	return function (dispatch) {
		dispatch(deselectMeetingSuccess());
	};
}
