// this is the movie page to load the most popular movies
import React from 'react';
import queryString from 'query-string';
import {
	getAuthCode,
	getAuthProvider,
	getLatestUrl,
	getPKCECodeVerifier,
	getState, removeAuthCode, removePKCECodeVerifier, removeState,
	setAuthCode, setIDToken,
	setToken,
} from '../utils/localStorage';
import history from '../store/history';
import { sendPostRequest } from '../utils/oidcHelper';

/* global CLOUD_AUTH_URL CLOUD_TOKEN_ENDPOINT CLOUD_AUTH_CLIENT_ID CLOUD_REDIRECT_URL */

/* eslint no-console:off */

class CallbackPage extends React.Component {
	componentDidMount() {
		const query = queryString.parse(window.location.search);
		if (query.error) {
			console.error(`Error returned from authorization server: ${query.error} : ${query.error_description}`);
			history.push('/login');
		}
		if (query.state !== getState()) {
			console.warn('invalid state, redirect to login page');
			history.push('/login');
		}
		removeState();
		if (query.code && query.code.length > 1) {
			setAuthCode(query.code);
		} else {
			console.warn('no auth code found in the url, redirect to login page');
			history.push('/login');
		}

		const authCode = getAuthCode();
		if (authCode) {
			// Exchange the authorization code for an access and Id token
			if (getAuthProvider() === 'CLOUD') {
				sendPostRequest(CLOUD_AUTH_URL + CLOUD_TOKEN_ENDPOINT, {
					grant_type: 'authorization_code',
					code: authCode,
					client_id: CLOUD_AUTH_CLIENT_ID,
					redirect_uri: CLOUD_REDIRECT_URL,
					code_verifier: getPKCECodeVerifier(),
				}, (request, body) => {
					if (body.access_token && body.access_token.length > 1) {
						setToken(body.access_token);
						setIDToken(body.id_token);
						// Replace the history entry to remove the auth code from the browser address bar
						window.history.replaceState({}, null, '/');
						let redir = '/';
						// eslint-disable-next-line no-unused-expressions
						(getLatestUrl()) ? redir = getLatestUrl() : redir = '/';
						history.push(redir);
					} else {
						console.warn('no access_token return from oidc, redirect to login page');
						history.push('/login');
					}
				}, (request, error) => {
					// This could be an error response from the OAuth server, or an error because the
					// request failed such as if the OAuth server doesn't allow CORS requests
					console.error(`${error.error} : ${error.error_description}`);
					history.push('/login');
				});
			}
		}
		removeAuthCode();
		removePKCECodeVerifier();
	}

	render() {
		return null;
	}
}

export default CallbackPage;

