import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import images from '../constants/images';

/* eslint no-console:off */

class MeetingTag extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filteredTags: [],
		};
	}

	componentDidMount() {
		this.setState({
			filteredTags: this.props.tags,
		});
	}

	searchTags = (event) => {
		let currentList = [];
		let newList = [];

		// If the search bar isn't empty
		if (event.target.value !== '') {
			currentList = this.props.tags;

			newList = currentList.filter((item) => {
				const lc = item.Tag.toLowerCase();
				const filter = event.target.value.toLowerCase();
				return lc.includes(filter);
			});
		} else {
			// If the search bar is empty, set newList to original task list
			newList = this.props.tags;
		}
		// Set the filtered state based on what our rules added to newList
		this.setState({
			filteredTags: newList,
		});
	}

	render() {
		const { selectedUser, recommendedTags } = this.props;
		const filteredTags = this.state.filteredTags;
		let recentTitleClass = 'none';
		if (recommendedTags.userRecent && recommendedTags.userRecent.length > 0) {
			recentTitleClass = 'block';
		}
		return (
			<Container className="tag-modal-container">
				<Row className="give-header-row">
					<img src={images.backButton} className="back-button-img" onClick={() => this.props.setMeetingTag()} alt="" />
					<div className="meeting-title">
						Select Meeting Tag for
						<span className="meeting-user-name">{selectedUser.FullName}</span>
					</div>
				</Row>
				<Row className="meeting-popular-title">
					<img src={images.singleLine} className="single-line-img" alt="" />
					<div className="title-div">most popular at next jump</div>
					<img src={images.singleLine} className="single-line-img" alt="" />
				</Row>
				<Row className="popular-button">
					<Button variant="outline-primary" onClick={() => this.props.setMeetingTag(recommendedTags.orgPopular.Tag)}>{recommendedTags.orgPopular.Tag}</Button>
					<Button variant="outline-primary" onClick={() => this.props.setMeetingTag(recommendedTags.orgRecent.Tag)}>{recommendedTags.orgRecent.Tag}</Button>
				</Row>
				<Row className="meeting-popular-title" style={{ display: recentTitleClass }}>
					<img src={images.singleLine} className="single-line-img" alt="" />
					<span>latest for {selectedUser.FullName.toLowerCase()}</span>
					<img src={images.singleLine} className="single-line-img" alt="" />
				</Row>
				<Row className="recent-button">
					{
						recommendedTags.userRecent.map((rtag, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<Button key={rtag.Tag + index} variant="outline-primary" onClick={() => this.props.setMeetingTag(rtag.Tag)}>{rtag.Tag}</Button>
						))
					}
				</Row>
				<Row className="all-tags-row">
					<Row className="search-tag-row">
						<Form className="search-tag-form">
							<Form.Control
								type="text"
								className="tag-search-box"
								placeholder="Search Meeting Tag by Name"
								onChange={this.searchTags}
							/>
						</Form>
					</Row>
					<Row className="tag-list-row">
						{
							filteredTags.map((tag, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<div key={tag.Tag + index} className="tagname-box" onClick={() => this.props.setMeetingTag(tag.Tag)} style={{ borderRightWidth: index % 2 === 1 ? '0px' : '1px' }}>
									<div className="tag-full-name">{tag.Tag}</div>
									<div className="tag-used-number">Used {tag.NumUsesForOrg} times</div>
								</div>
							))
						}
					</Row>
				</Row>
			</Container>
		);
	}
}

MeetingTag.propTypes = {
	recommendedTags: PropTypes.object,
	tags: PropTypes.array,
	selectedUser: PropTypes.object.isRequired,
	setMeetingTag: PropTypes.func,
};

export default MeetingTag;
