import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* eslint no-console:off */

// eslint-disable-next-line react/prefer-stateless-function
class UserImage extends Component {
	render() {
		const { firstName, lastName, imageUrl, size } = this.props;
		if (imageUrl === '') {
			const initials = (firstName && firstName.charAt(0) ? firstName.charAt(0) : '') + (lastName && lastName.charAt(0) ? lastName.charAt(0) : '');
			return (
				<div className={(size && size !== '') ? 'avatar-wrapper avatar-smallFont' : 'avatar-wrapper'}>
					<div className="initial-box">
						{initials}
					</div>
				</div>
			);
		}

		return (
			<div className="avatar-wrapper">
				<img src={this.props.imageUrl} className="feed-avatar" alt="" />
			</div>
		);
	}
}

UserImage.propTypes = {
	imageUrl: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	size: PropTypes.string,
};

export default UserImage;
