import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import Container from 'react-bootstrap/Container';
import CardDeck from 'react-bootstrap/CardDeck';
import GiveModal from './containers/GiveModal';
import SingleUserCard from './SingleUserCard';
/* eslint no-console:off */

class UserList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tracks: [],
			showModal: false,
			selectedUser: null,
			msg: '',
		};
	}

	showGiveModal = (selectedUser) => {
		this.setState({
			showModal: true,
			selectedUser,
		});
	}

	showMsg = (msg) => {
		this.setState({ msg }, () => {
			setTimeout(() => {
				this.setState({ msg: '' });
			}, 2000);
		});
	}

	lgClose = () => {
		this.setState({
			showModal: false,
			selectedUser: null,
		});
	}

	render() {
		const { users, notFound } = this.props;
		const loader = <div key={0} className="loader">Loading ...</div>;

		const items = [];
		if (users) {
			// eslint-disable-next-line array-callback-return
			users.map((user, index) => {
				items.push(
					<SingleUserCard
						key={user.UserId}
						user={user}
						index={index}
						showGiveModal={this.showGiveModal}
						selectUser={this.props.selectUser}
					/>,
				);
			});
		}

		if (users.length === 0 && notFound) {
			items.push(
				<div key="0_notfound" className="not-found-div">Sorry, could not find any Employees with given search criteria.</div>,
			);
		}

		return (
			<Container className="page-inner-container">
				<div className={`success-msg ${this.state.msg.length > 0 ? 'success-msg-show' : 'success-msg-hidden'}`}>{this.state.msg}</div>
				<InfiniteScroll
					pageStart={0}
					loadMore={this.props.loadItems}
					hasMore={this.props.hasMoreItems}
					loader={loader}
				>

					<CardDeck>
						{items}
					</CardDeck>

				</InfiniteScroll>

				<GiveModal
					selectedUser={this.state.selectedUser}
					showModal={this.state.showModal}
					showMsg={this.showMsg}
					showGiveModal={this.showGiveModal}
					lgClose={this.lgClose}
					selectUser={this.props.selectUser}
				/>

			</Container>
		);
	}
}

UserList.propTypes = {
	users: PropTypes.array.isRequired,
	notFound: PropTypes.bool.isRequired,
	hasMoreItems: PropTypes.bool.isRequired,
	loadItems: PropTypes.func.isRequired,
	selectUser: PropTypes.func.isRequired,
};

export default UserList;
