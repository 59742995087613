/* eslint-disable func-names */
// Generate a secure random string using the browser crypto functions
export function generateRandomString() {
	const array = new Uint32Array(28);
	window.crypto.getRandomValues(array);
	return Array.from(array, (dec) => (`0${dec.toString(16)}`).substr(-2)).join('');
}

// Calculate the SHA256 hash of the input text.
// Returns a promise that resolves to an ArrayBuffer
function sha256(plain) {
	const encoder = new TextEncoder();
	const data = encoder.encode(plain);
	return window.crypto.subtle.digest('SHA-256', data);
}

// Base64-urlencodes the input string
function base64urlencode(str) {
	// Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
	// btoa accepts chars only within ascii 0-255 and base64 encodes them.
	// Then convert the base64 encoded to base64url encoded
	//   (replace + with -, replace / with _, trim trailing =)
	return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
		.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge
export async function pkceChallengeFromVerifier(v) {
	const hashed = await sha256(v);
	return base64urlencode(hashed);
}

// Make a POST request and parse the response as JSON
export function sendPostRequest(url, params, success, error) {
	const request = new XMLHttpRequest();
	request.open('POST', url, true);
	request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
	request.onload = function () {
		let body = {};
		try {
			body = JSON.parse(request.response);
			// eslint-disable-next-line no-unused-expressions
		} catch (e) { e; }

		if (request.status === 200) {
			success(request, body);
		} else {
			error(request, body);
		}
	};
	request.onerror = function () {
		error(request, {});
	};
	const body = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
	request.send(body);
}
