import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import images from '../constants/images';
import { getRandomInt } from '../utils/math';
import UserImage from './UserImage';
import VoteButton from './VoteButton';

const getRatingColor = (rating) => {
	const map = {
		1: '#D65745',
		2: '#E79F3C',
		3: '#83BD63',
		4: '#4CA8D1',
	};

	return map[rating];
};

const TimeZoneOffset = new Date().getTimezoneOffset() * 60;

class SingleFeedCard extends Component {
	render() {
		const { fb, index } = this.props;
		const ratingColor = getRatingColor(fb.Rating);

		let timeZoneOffset = TimeZoneOffset / 3600;

		if (timeZoneOffset < 0) {
			timeZoneOffset = 0;
		}

		const timeStamp = moment.utc(fb.DatetimeCreated).add(timeZoneOffset, 'hours');
		let fbDate = timeStamp.format('MMMM DD, YYYY');
		if (moment().diff(timeStamp, 'h') < 24) {
			fbDate = timeStamp.fromNow();
			if (moment().diff(timeStamp, 'h') >= 21) {
				fbDate = `${moment().diff(timeStamp, 'h')} hours ago`;
			}
		}

		const teamList = [];

		const teamLimit = 3;
		if (fb.Team) {
			// eslint-disable-next-line array-callback-return
			fb.Team.map((teamName, i) => {
				if (i > teamLimit) {
					return;
				}
				teamList.push(
					<span key={fb.ResponseId + getRandomInt()} className="team-span">{teamName}</span>,
				);
				if (i < teamLimit && i < fb.Team.length - 1) {
					teamList.push(
						<span key={fb.ResponseId + getRandomInt()} className="team-middot">&middot;</span>,
					);
				}
			});
		}

		return (
			<Row className="single-feed-box" style={{ borderLeft: `5px solid ${ratingColor}` }} key={fb.ResponseId + index}>
				<Row className="feed-top-row">
					<Col xs={2} className="image-box" onClick={() => this.props.selectUser(fb)}>
						<UserImage imageUrl={fb.ImageUrl} firstName={fb.PreferredName} lastName={fb.LastName} />
					</Col>
					<Col xs={6} className="name-box">
						<div className="name-box-title text-black" onClick={() => this.props.selectUser(fb)}>
							{fb.FullName}
						</div>
						<div className="name-box-teams text-font text-light-grey">
							{teamList}
						</div>
					</Col>
					<Col xs={4} className="voting-box">
						<VoteButton
							fb={fb}
							index={index}
							vote={this.props.vote}
							renderFlag={this.props.renderFlag}
						/>
					</Col>
				</Row>
				<Row className="feed-content-row">
					<Col xs={2} className="rating-box">
						<div className="rating-score-circle" style={{ backgroundColor: ratingColor }}>{fb.Rating}</div>
					</Col>
					<Col xs={10} className="comment-outer-box">
						<Row className="comment-box text-font text-black">
							{fb.Comment}
						</Row>
						<Row className="feed-time-row">
							<div className="time-meeting-box text-font text-light-grey">
								<img src={images.timeIcon} className="timeIcon" alt="" /><span>{fbDate}</span>&nbsp;&nbsp;&nbsp;
								<img src={images.meetingIcon} className={(fb.Tag === null || fb.Tag === '') ? 'meetingIcon displayNone' : 'meetingIcon'} alt="" /><span>{fb.Tag}</span>
							</div>
						</Row>
					</Col>
				</Row>
			</Row>
		);
	}
}

SingleFeedCard.propTypes = {
	fb: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	vote: PropTypes.func.isRequired,
	renderFlag: PropTypes.func.isRequired,
	selectUser: PropTypes.func.isRequired,
};

export default SingleFeedCard;
