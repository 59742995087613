/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default */
import { Route, Switch } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import React from 'react';
import { hot } from 'react-hot-loader';
import queryString from 'query-string';
import UsersPage from './containers/UsersPage';
import StreamPage from './containers/StreamPage';
import MeetingPage from './containers/MeetingPage';
import ProfilePage from './containers/ProfilePage';
import SelectedUserPage from './containers/SelectedUserPage';
import NotFoundPage from './NotFoundPage';
import TopNav from './containers/TopNav';
import { getLatestUrl, getToken, setToken } from '../utils/localStorage';
import { validateToken } from '../utils/tokenHelper';
import history from '../store/history';
import { store } from '../store/configureStore';
import { loadSelf } from '../actions/userActions';

// This is a class-based component because the current
// version of hot reloading won"t hot reload a stateless
// component at the top-level.

/* eslint no-console:off */

class App extends React.Component {
	constructor(props) {
		super(props);

		// set token from auth
		const query = queryString.parse(window.location.search);
		if (query.access_jwt && query.access_jwt.length > 1) {
			setToken(query.access_jwt);
			// Replace the history entry to remove the tokens from the browser address bar
			window.history.replaceState({}, null, '/');
			let redir = '/';
			// eslint-disable-next-line no-unused-expressions
			(getLatestUrl()) ? redir = getLatestUrl() : redir = '/';
			history.push(redir);
		} else {
			// eslint-disable-next-line no-unused-expressions
			(getToken()) ? history.push('/') : history.push('/login');
		}
	}

	componentDidMount() {
		const token = getToken();
		if (token) {
			// valid token
			validateToken(token).then((valid) => {
				if (!valid) {
					console.warn('invalid token, redirect to login page');
					history.push('/login');
				}
			});

			store.dispatch(loadSelf());
		}
	}

	componentDidUpdate(prevProps) {
		const { pathname, search } = window.location;
		const fullPath = pathname + search;
		const prevPathname = prevProps.location.pathname;
		const prevSearch = prevProps.location.search;
		const prevFullPath = prevPathname + prevSearch;
		if (fullPath !== prevFullPath) {
			localStorage.setItem('latest-url', fullPath);
		}
	}

	render() {
		if (!getToken()) {
			return null;
		}

		return (
			<Container fluid="true">
				<TopNav />
				<Switch>
					<Route exact path="/give" component={UsersPage} />
					<Route exact path="/" component={StreamPage} />
					<Route exact path="/stream" component={StreamPage} />
					<Route exact path="/meetings" component={MeetingPage} />
					<Route exact path="/profile" component={ProfilePage} />
					<Route exact path="/user" component={SelectedUserPage} />
					<Route component={NotFoundPage} />
				</Switch>
			</Container>
		);
	}
}

export default hot(module)(App);
