import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import '../styles/notfound-page.css';

const NotFoundPage = () => (
	<Container id="pageContainer">
		<div className="page-not-found">
				404 Page Not Found
		</div>
		<Link className="goback-link" to="/"> Go back to homepage </Link>
	</Container>
);

export default NotFoundPage;
