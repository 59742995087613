import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import images from '../constants/images';
import UserImage from './UserImage';

class SingleMeetingTile extends Component {
	render() {
		const { m, index } = this.props;
		const participantList = [];
		if (m.Participants) {
			// eslint-disable-next-line array-callback-return
			m.Participants.map((participant, i) => {
				if (i > 5 && m.Participants.length > 7) {
					return;
				}
				participantList.push(
					// eslint-disable-next-line react/no-array-index-key
					<div key={participant.UserId + i} className="user-image-box">
						<UserImage imageUrl={participant.ImageUrl} firstName={participant.FirstName} lastName={participant.LastName} size="small" />
					</div>,
				);
			});

			if (m.Participants.length > 7) {
				participantList.push(
					<div key={0} className="extra-number-text">+ {m.Participants.length - 6} more</div>,
				);
			}
		}

		return (
			<Row className="single-meeting-row" key={m.TagUuid + index}>
				<Col xs={9} className="meeting-main-box" onClick={() => this.props.showLeaderboardModal(m, '')}>
					<Row className="meeting-title">
						<img src={images.meetingTagDark} className="meetingIcon" alt="" />
						<span>{m.Title}</span>
					</Row>
					{m && m.IsLive && (
						<Row className="meeting-status">
							<div className="red-dot" />
							<span className="live-tag">HAPPENING NOW</span>
						</Row>
					)}
					<Row className="participant-list-row">
						{participantList}
					</Row>
				</Col>
				<Col xs={3} className="meeting-button-box">
					<div className="button-wrapper">
						<Row onClick={() => this.props.showLeaderboardModal(m, '')}>
							<img src={images.snapshotIcon} className="link-icons" alt="" />
							<span>VIEW SNAPSHOT</span>
						</Row>
						<Row onClick={() => this.props.showFeedbackForMeeting(m)}>
							<img src={images.feedbackIcon} className="link-icons" alt="" />
							<span>VIEW FEEDBACK</span>
						</Row>
					</div>
				</Col>
			</Row>
		);
	}
}

SingleMeetingTile.propTypes = {
	m: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	showLeaderboardModal: PropTypes.func.isRequired,
	showFeedbackForMeeting: PropTypes.func.isRequired,
};

export default SingleMeetingTile;
