// this file contains all the actions that are dispatched from the containers
import moment from 'moment';

/* eslint no-console:off */

export function setGlobalFilters(filters = {}) {
	const globalFilters = {};

	if (filters.length === 0) {
		return {
			type: 'NO_FILTER_FOUND',
		};
	}

	const { team, sort, startDate, endDate, tag, tagId } = filters;
	if (team && team.length > 0) {
		globalFilters.team = team;
	}

	if (tagId && tagId.length > 0) {
		globalFilters.tagId = tagId;
		globalFilters.tag = tag;
	}

	if (startDate && startDate.length > 0) {
		// TODO: need to check date format
		globalFilters.startDate = startDate;
	}

	if (endDate && endDate.length > 0) {
		// endDate has to be later than startDate at least by a day
		const start = moment(startDate);
		let end = moment(endDate);
		if (end <= start) {
			end = start.add(1, 'day');
		}
		globalFilters.endDate = end.format('YYYY-MM-DD');
	}

	if (sort && sort.length > 0) {
		globalFilters.sort = sort;

		// make sure the date range is set for sorting that's not by time
		// hard code for v1
		if (!globalFilters.startDate && !globalFilters.endDate) {
			const end = moment(new Date()).add(1, 'days');
			const start = moment(new Date()).add(-7, 'days');
			globalFilters.startDate = start.format('YYYY-MM-DD');
			globalFilters.endDate = end.format('YYYY-MM-DD');
		}
	}

	return {
		type: 'SET_GLOBAL_FILTERS_SUCCESS',
		globalFilters,
	};
}

export function resetGlobalFilters(toReset = []) {
	if (toReset.length === 0) {
		return {
			type: 'RESET_GLOBAL_FILTERS_ALL',
		};
	}

	return {
		type: 'RESET_GLOBAL_FILTERS_PARTIAL',
		toReset,
	};
}
