// this is the movie page to load the most popular movies
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/profile-page.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/* eslint no-console:off */

class UserBar extends React.Component {
	scoreTextColor = (expectationScore) => {
		let textColor = '';
		if (expectationScore < 1.7 || expectationScore > 2.5) {
			textColor = '#AF2419';
		} else if (expectationScore < 2.01 && expectationScore > 1.85) {
			textColor = '#3F5728';
		} else {
			textColor = '#7AA05E';
		}
		return textColor;
	}

	render() {
		const { totalFeedback, rated1, rated2, rated3, rated4 } = this.props.metrics;
		let rated1Percentage = '100%';
		let rated2Percentage = '100%';
		let rated3Percentage = '100%';
		let rated4Percentage = '100%';

		if (totalFeedback > 0) {
			rated1Percentage = `${Math.round((rated1 / totalFeedback) * 100)}%`;
			rated2Percentage = `${Math.round((rated2 / totalFeedback) * 100)}%`;
			rated3Percentage = `${Math.round((rated3 / totalFeedback) * 100)}%`;
			rated4Percentage = `${Math.round((rated4 / totalFeedback) * 100)}%`;
		}

		const expectationScore = this.props.metrics.expectationScore.toFixed(2);

		return (
			<Container className="page-inner-container">
				<div className="right-user-box">
					<div className="user-top-area" />
					<div className="user-name-section">
						{this.props.user.FullName}
					</div>
					<div className="user-company-section">
						{this.props.user.OrgName} Employee
					</div>
					<div className="user-numbers-section">
						<div className="participation-number">
							<div className="total-fb-number">{totalFeedback}</div>
							<div className="total-fb-title">TOTAL FEEDBACK</div>
						</div>
						<div className="expectation-number">
							<div className="total-expec-number" style={{ color: this.scoreTextColor(expectationScore) }}>{expectationScore}</div>
							<div className="total-expec-title">EXPECTATION</div>
						</div>
					</div>
					<div className="user-logo-box">
						<div className="avatar-wrapper">
							<img src={this.props.user.ImageUrl} className="feed-avatar" alt="" />
						</div>
					</div>
					<Row className="time-period-text">
						<Col>ALL TIME STATS</Col>
					</Row>
				</div>
				<div className="right-user-box single-box">
					<div className="profile-top-area">
						FEEDBACK DISTRIBUTION
					</div>
					<Container className="profile-details-container">
						<div className="profile-stats-section">
							<div className="profile-stats-section-label">FAR EXCEEDS: {rated4}</div>
							<div className="profile-distribution-bar">
								<div className="profile-distibution-bar-four" style={{ width: rated4Percentage }} />
							</div>
						</div>
						<div className="profile-stats-section">
							<div className="profile-stats-section-label">EXCEEDS: {rated3}</div>
							<div className="profile-distribution-bar">
								<div className="profile-distibution-bar-three" style={{ width: rated3Percentage }} />
							</div>
						</div>
						<div className="profile-stats-section">
							<div className="profile-stats-section-label">MEETS: {rated2}</div>
							<div className="profile-distribution-bar">
								<div className="profile-distibution-bar-two" style={{ width: rated2Percentage }} />
							</div>
						</div>
						<div className="profile-stats-section">
							<div className="profile-stats-section-label">BELOW: {rated1}</div>
							<div className="profile-distribution-bar">
								<div className="profile-distibution-bar-one" style={{ width: rated1Percentage }} />
							</div>
						</div>
					</Container>
				</div>
			</Container>
		);
	}
}

UserBar.propTypes = {
	metrics: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default UserBar;
