/* eslint-disable import/default */

import React from 'react';
import { render } from 'react-dom';
import { AppContainer, setConfig } from 'react-hot-loader';
import history from './store/history';
import { store } from './store/configureStore';
import Root from './components/Root';
import './styles/styles.scss';

// for disable the annoying hot reload error in the console
setConfig({ logLevel: 'no-errors-please' });

render(
	<AppContainer>
		<Root store={store} history={history} />
	</AppContainer>,
	document.getElementById('app'),
);

if (module.hot) {
	module.hot.accept('./components/Root', () => {
		const NewRoot = require('./components/Root').default;
		render(
			<AppContainer>
				<NewRoot store={store} history={history} />
			</AppContainer>,
			document.getElementById('app'),
		);
	});
}
