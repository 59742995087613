import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import history from '../store/history';
import SingleFeedCard from './SingleFeedCard';
import ProfileSingleFeedCard from './ProfileSingleFeedCard';

/* eslint no-console:off */

class StreamList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tracks: [],
		};
	}

	goToMeetingPage = () => {
		this.props.deselectMeeting();
		history.push('/meetings');
	}

	render() {
		const { feedback, filter } = this.props;
		const loader = <div key={0} className="loader">Nothing yet ...</div>;

		let items = [];
		let appliedFilterText = '';

		if (feedback && filter && filter.tag) {
			let filterDate = '';
			if (filter.startDate) {
				filterDate = moment(filter.startDate).format('ddd, D MMMM, YYYY').toUpperCase();
			}
			appliedFilterText
				= (
					<div key="filter-meeting-0" className="filter-meeting-textbox">
						<span>{filter.tag.toUpperCase()}</span>
						<span className="filter-middot">&middot;</span>
						<span>{filterDate}</span>
						<span className="back-link" onClick={() => this.goToMeetingPage()}>Back &gt;</span>
					</div>
				);
		}

		if (feedback) {
			if (this.props.isHomeStream) {
				items = feedback.map((fb, index) => (
					<SingleFeedCard
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						fb={fb}
						index={index}
						vote={this.props.vote}
						renderFlag={this.props.renderFlag}
						isHomeStream
						selectUser={this.props.selectUser}
					/>
				));
			} else {
				items = feedback.map((fb, index) => (
					<ProfileSingleFeedCard
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						fb={fb}
						index={index}
						vote={this.props.vote}
						renderFlag={this.props.renderFlag}
					/>
				));
			}
		}

		return (
			<Col>
				<Container className="page-inner-container">
					{appliedFilterText}
					<InfiniteScroll
						pageStart={0}
						loadMore={this.props.loadItems}
						hasMore={this.props.hasMoreItems}
						loader={loader}
					>
						{items}
					</InfiniteScroll>
				</Container>
			</Col>
		);
	}
}

StreamList.propTypes = {
	feedback: PropTypes.array.isRequired,
	hasMoreItems: PropTypes.bool.isRequired,
	loadItems: PropTypes.func.isRequired,
	vote: PropTypes.func.isRequired,
	renderFlag: PropTypes.func.isRequired,
	filter: PropTypes.object,
	isHomeStream: PropTypes.bool.isRequired,
	selectUser: PropTypes.func,
	deselectMeeting: PropTypes.func,
};

export default StreamList;

