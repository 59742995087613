import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import images from '../../constants/images';
import * as actions from '../../actions/feedbackActions';
import { reload } from '../../utils/reloader';
import MeetingTag from '../MeetingTag';
import UserImage from '../UserImage';

/* eslint no-console:off */

const ratingMap = {
	1: { text: 'BELOW', color: '#D65745', placeholder: "Please be candid about what's wrong and why." },
	2: { text: 'MEETS', color: '#E79F3C', placeholder: "Please be specific about why they're headed in the right direction." },
	3: { text: 'EXCEEDS', color: '#83BD63', placeholder: 'Please describe exactly what impressed you and why.' },
	4: { text: 'FAR EXCEEDS', color: '#4CA8D1', placeholder: 'Please be specific about what was inspiring.' },
};

const DefaultPlaceholder = 'Please select a rating then give your feedback.';
const ErrInvalidRating = 'Submit Error: The rating is not valid. Please make sure you give a rating.';
const ErrShortComment = 'Submit Error: Your comment is too short.';
const ErrNoReceiver = 'Submit Error: No recipient set.';

const PostFBSuccess = 'Your feedback was posted successfully!';

class GiveModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			commentValue: DefaultPlaceholder,
			error: '',

			comment: '',
			rating: 0,
			tags: [],
			recipientId: '',
			showMeetingTag: false,
		};
	}

	getRatingAttri = (rating) => ratingMap[rating]

	componentDidUpdate() {
		if (!this.props.selectedUser) return;
		if (this.state.recipientId !== this.props.selectedUser.UserId) {
			// reset rating state
			this.reset();
			this.props.actions.loadRecommendedTags(this.props.selectedUser.UserId);
			this.props.actions.loadTags();
		}
	}

	reset = () => {
		this.setState({
			commentValue: DefaultPlaceholder,
			error: '',
			comment: '',
			rating: 0,
			tags: [],
			recipientId: this.props.selectedUser.UserId,
			showMeetingTag: false,
		});
	}

	setRating = (value) => {
		const rating = Number.parseInt(value, 10);
		if (Number.isNaN(rating)) return;

		// update rating
		// update comment placeholder
		this.setState({
			commentValue: this.getRatingAttri(rating).placeholder,
			rating,
		});
	}

	setComment = (event) => {
		const comment = event.target.value;

		// update comment value
		this.setState({ comment });
	}

	setMeetingTag = (tag = '') => {
		let tagArray = [tag];
		if (tag === '') {
			tagArray = [];
		}
		this.setState({
			tags: tagArray,
			showMeetingTag: false,
		});
	}

	openMeetingTag = () => {
		this.setState({
			showMeetingTag: true,
		});
	}

	submit = () => {
		const { rating, comment, tags, recipientId } = this.state;
		if (typeof rating !== 'number' ||
			rating === 0) {
			console.warn(ErrInvalidRating);
			this.setState({ error: ErrInvalidRating });
			return;
		}

		if (comment.length < 5) {
			console.warn(ErrShortComment);
			this.setState({ error: ErrShortComment });
			return;
		}

		if (recipientId.length === 0) {
			console.warn(ErrNoReceiver);
			this.setState({ error: ErrNoReceiver });
			return;
		}

		this.props.actions.postFeedback(recipientId, rating, comment, tags).then((resp) => {
			if (typeof resp === 'string') {
				this.setState({ error: `API Error:${resp}` });
				return;
			}

			// close modal
			// show message on user page
			this.reset();
			this.props.lgClose();
			this.props.showMsg(PostFBSuccess);
			reload(['stream', 'meetings']);
		});
	}

	renderRatingGrid = () => {
		let cols = [];
		Object.keys(ratingMap).forEach((key) => {
			// eslint-disable-next-line no-prototype-builtins
			if (ratingMap.hasOwnProperty(key)) {
				let selectedBgColor = '#FFFFFF';
				let selectedTextColor = ratingMap[key].color;
				// eslint-disable-next-line eqeqeq
				if (key == this.state.rating) {
					selectedBgColor = ratingMap[key].color;
					selectedTextColor = '#FFFFFF';
				}
				const col = (
					<Col key={ratingMap[key].text} onClick={() => this.setRating(key)}>
						<Row className="rating-number-box">
							<div className="rating-number-text">
								<div className="rating-text-font" style={{ color: selectedTextColor, backgroundColor: selectedBgColor }}>
									{key}
								</div>
							</div>
						</Row>
						<Row className="rating-text-box">
							<span className="rating-text-name">{ratingMap[key].text}</span>
							<span>Expectations</span>
						</Row>
					</Col>
				);

				cols = [...cols, col];
			}
		});
		return (<Row>{cols}</Row>);
	}

	renderRatingDetails = () => {
		const user = this.props.selectedUser;

		if (this.state.showMeetingTag) {
			return (
				<MeetingTag
					recommendedTags={this.props.recommendedTags}
					tags={this.props.tags}
					selectedUser={user}
					setMeetingTag={this.setMeetingTag}
				/>
			);
		}

		const teamList = [];
		const teamLimit = 1;
		if (user.Team) {
			// eslint-disable-next-line array-callback-return
			user.Team.map((teamName, index) => {
				if (index > teamLimit) {
					return;
				}
				teamList.push(
					// eslint-disable-next-line react/no-array-index-key
					<span key={user.UserId + teamName + index} className="team-span">{teamName}</span>,
				);
				if (index < teamLimit && user.Team.length > teamLimit) {
					teamList.push(
						// eslint-disable-next-line react/no-array-index-key
						<span key={user.UserId + index} className="team-middot">&middot;</span>,
					);
				}
			});
		}

		let currentMeetingTag = 'No Meeting Selected';
		if (this.state.tags && this.state.tags.length > 0) {
			currentMeetingTag = this.state.tags[0];
		}

		return (
			<Container className="give-modal-container">
				<Row className="give-header-row">
					<Col xs={2} className="user-image-box">
						<UserImage
							imageUrl={user.ImageUrl}
							firstName={user.PreferredName}
							lastName={user.LastName}
						/>
					</Col>
					<Col xs={7} className="name-box">
						<Row className="name-box-title text-black">
							{user.FullName}
						</Row>
						<Row className="name-box-teams text-font text-light-grey">
							{teamList}
						</Row>
					</Col>
					<Col xs={3} className="profile-button">
						<Button variant="outline-primary" onClick={() => this.props.selectUser(this.props.selectedUser)}>see profile</Button>
					</Col>
				</Row>
				<Row className="give-rating-box">
					<Container className="rating-grid-container">
						{this.renderRatingGrid()}
					</Container>
				</Row>
				<Row className="give-meeting-tag-box">
					<Container className="meeting-tag-container">
						<div className="meeting-tag-text" onClick={this.openMeetingTag}>
							<img src={images.meetingTagIcon} className="meeting-tag-icon" alt="" />
							<span>{currentMeetingTag}</span>
						</div>
					</Container>
				</Row>
				<Row className="give-comment-box">
					<Container className="comment-box-container">
						<textarea className="comment-textarea" placeholder={this.state.commentValue} value={this.state.comment} onChange={this.setComment} maxLength="1000" />
					</Container>
				</Row>
				<Row className="give-post-feedback">
					<Container className="post-button-container">
						<div className="err-msg">{this.state.error}</div>
						<Button variant="primary" onClick={this.submit}>POST FEEDBACK</Button>
					</Container>
				</Row>
			</Container>
		);
	}

	render() {
		if (!this.props.selectedUser) {
			return null;
		}

		return (
			<Modal
				size="md"
				show={this.props.showModal}
				onHide={this.props.lgClose}
				aria-labelledby="example-modal-sizes-title-lg"
			>
				{this.renderRatingDetails()}
			</Modal>
		);
	}
}

GiveModal.propTypes = {
	recommendedTags: PropTypes.object,
	tags: PropTypes.array,
	actions: PropTypes.object.isRequired,

	selectedUser: PropTypes.object,
	showModal: PropTypes.bool.isRequired,
	showMsg: PropTypes.func.isRequired,
	lgClose: PropTypes.func.isRequired,
	selectUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
	return {
		users: state.user.users,
		recommendedTags: state.feedback.recommended,
		tags: state.feedback.tags,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GiveModal);
