// user types 
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const RELOAD_USERS_SUCCESS = 'RELOAD_USERS_SUCCESS';
export const LOAD_USER_BY_ID_SUCCESS = 'LOAD_USER_BY_ID_SUCCESS';
export const LOAD_SELF_SUCCESS = 'LOAD_SELF_SUCCESS';
export const LOAD_SELF_METRICS_SUCCESS = 'LOAD_SELF_METRICS_SUCCESS';
export const LOAD_SELF_METRICS_FAILURE = 'LOAD_SELF_METRICS_FAILURE';
export const LOAD_USER_METRICS_SUCCESS = 'LOAD_USER_METRICS_SUCCESS';
export const LOAD_USER_METRICS_FAILURE = 'LOAD_USER_METRICS_FAILURE';
export const LOAD_SELF_FEEDBACK_SUCCESS = 'LOAD_SELF_FEEDBACK_SUCCESS';
export const LOAD_SELF_FEEDBACK_FAILURE = 'LOAD_SELF_FEEDBACK_FAILURE';
export const LOAD_USER_FEEDBACK_SUCCESS = 'LOAD_USER_FEEDBACK_SUCCESS';
export const LOAD_USER_FEEDBACK_FAILURE = 'LOAD_USER_FEEDBACK_FAILURE';
export const NO_SELF_FILTER_FOUND = 'NO_SELF_FILTER_FOUND';
export const SET_SELF_FILTERS_SUCCESS = 'SET_SELF_FILTERS_SUCCESS';
export const RESET_SELF_FILTERS_ALL = 'RESET_SELF_FILTERS_ALL';
export const RESET_SELF_FILTERS_PARTIAL = 'RESET_SELF_FILTERS_PARTIAL';
export const NO_USER_FILTER_FOUND = 'NO_USER_FILTER_FOUND';
export const SET_USER_FILTERS_SUCCESS = 'SET_USER_FILTERS_SUCCESS';
export const RESET_USER_FILTERS_ALL = 'RESET_USER_FILTERS_ALL';
export const RESET_USER_FILTERS_PARTIAL = 'RESET_USER_FILTERS_PARTIAL';
export const SELECT_USER_SUCCESS = 'SELECT_USER_SUCCESS';
export const DESELECT_USER_SUCCESS = 'DESELECT_USER_SUCCESS';

// feedback types
export const LOAD_FEEDBACK_STREAM_SUCCESS = 'LOAD_FEEDBACK_STREAM_SUCCESS';
export const RELOAD_FEEDBACK_STREAM_SUCCESS = 'RELOAD_FEEDBACK_STREAM_SUCCESS';
export const LOAD_MEETINGS_SUCCESS = 'LOAD_MEETINGS_SUCCESS';
export const RELOAD_MEETINGS_SUCCESS = 'RELOAD_MEETINGS_SUCCESS';
export const LOAD_LEADERBOARD_STREAM_SUCCESS = 'LOAD_LEADERBOARD_STREAM_SUCCESS';
export const LOAD_DAY_LEADERBOARD_SUCCESS = 'LOAD_DAY_LEADERBOARD_SUCCESS';
export const LOAD_TEAMS_SUCCESS = 'LOAD_TEAMS_SUCCESS';
export const LOAD_METRICS_SUCCESS = 'LOAD_METRICS_SUCCESS';
export const LOAD_METRICS_FAILURE = 'LOAD_METRICS_FAILED';
export const LOAD_TAGS_SUCCESS = 'LOAD_TAGS_SUCCESS';
export const LOAD_RECOMMENDED_TAGS_SUCCESS = 'LOAD_RECOMMENDED_TAGS_SUCCESS';
export const POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS';
export const POST_FEEDBACK_FAILURE = 'POST_FEEDBACK_FAILURE';
export const POST_VOTE_SUCCESS = 'POST_VOTE_SUCCESS';
export const POST_VOTE_FAILURE = 'POST_VOTE_FAILURE';
export const POST_FLAG_SUCCESS = 'POST_FLAG_SUCCESS';
export const POST_FLAG_FAILURE = 'POST_FLAG_FAILURE';
export const DESELECT_LEADERBOARD_SUCCESS = 'DESELECT_LEADERBOARD_SUCCESS';
export const SELECT_MEETING_SUCCESS = 'SELECT_MEETING_SUCCESS';
export const DESELECT_MEETING_SUCCESS = 'DESELECT_MEETING_SUCCESS';

// filter types
export const NO_FILTER_FOUND = 'NO_FILTER_FOUND';
export const SET_GLOBAL_FILTERS_SUCCESS = 'SET_GLOBAL_FILTERS_SUCCESS';
export const RESET_GLOBAL_FILTERS_ALL = 'RESET_GLOBAL_FILTERS_ALL';
export const RESET_GLOBAL_FILTERS_PARTIAL = 'RESET_GLOBAL_FILTERS_PARTIAL';
