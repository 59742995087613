import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import * as userActions from '../../actions/userActions';
import * as actions from '../../actions/feedbackActions';
import history from '../../store/history';
import UserImage from '../UserImage';

/* eslint no-console:off */

const dateFormat = 'YYYY-MM-DD';
const oneSecond = 1000;

class LeaderboardModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedMeeting: null,
			selectedDay: '',
		};
		this.refreshInterval = null;
	}

	componentDidUpdate() {
		const { selectedMeeting, selectedDay } = this.props;
		let diff = 0;
		if (selectedMeeting && selectedMeeting !== this.state.selectedMeeting) {
			this.setState({ selectedMeeting });
			const startDate = moment(selectedMeeting.StartDatetime).startOf('day').format();
			const endDate = moment(selectedMeeting.StartDatetime).endOf('day').format();
			const id = selectedMeeting.IsLive ? selectedMeeting.EventUuid : selectedMeeting.TagUuid;
			this.props.actions.loadLeaderboard(
				id, startDate, endDate, selectedMeeting.IsLive,
			);
			diff = moment().diff(moment(selectedMeeting.StartDatetime), 'days');
			if (diff < 2) {
				this.refreshInterval = setInterval(() => {
					this.props.actions.loadLeaderboard(
						id, startDate, endDate, selectedMeeting.IsLive,
					);
				}, 2 * oneSecond);
			}
		} else if (selectedDay.length > 0 && selectedDay !== this.state.selectedDay) {
			this.setState({ selectedDay });
			const startDate = moment(selectedDay).format(dateFormat);
			const endDate = moment(startDate).add(1, 'days').format(dateFormat);
			this.props.actions.loadDayLeaderboard(startDate, endDate);
			diff = moment().diff(moment(selectedDay), 'days');
			if (diff < 2) {
				this.refreshInterval = setInterval(() => {
					this.props.actions.loadDayLeaderboard(startDate, endDate);
				}, 2 * oneSecond);
			}
		} else if (selectedMeeting === null && selectedDay === '' && (this.state.selectedMeeting || this.state.selectedDay !== '')) {
			this.setState({ selectedMeeting, selectedDay });
		}

		if (!this.props.showModal && !(this.props.selectedDay.length > 0) && this.refreshInterval) {
			clearInterval(this.refreshInterval);
		}
	}

	selectUser = (user) => {
		this.props.lgClose();
		clearInterval(this.refreshInterval);
		if (user.UserId === this.props.self.UserId) {
			history.push('/profile');
		} else {
			this.props.userActions.selectUser(user);
			history.push('/user');
		}
	}

	renderLeaderboardBody = () => {
		const { leaderboard } = this.props;
		if (leaderboard.length > 0) {
			return (
				<tbody>
					{leaderboard.map((l) => (
						<tr key={l.User.UserId}>
							<td className="number-td-column">{l.Rank}</td>
							<td className="pic-td-column" onClick={() => this.selectUser(l.User)}>
								<div className="user-image-box">
									<UserImage imageUrl={l.User.ImageUrl} firstName={l.User.FirstName} lastName={l.User.LastName} size="small" />
								</div>
							</td>
							<td className="name-td-column" onClick={() => this.selectUser(l.User)}>{l.User.FullName}</td>
							<td className="rating-td-column">{l.RatingCount[1]}</td>
							<td className="rating-td-column">{l.RatingCount[2]}</td>
							<td className="rating-td-column">{l.RatingCount[3]}</td>
							<td className="rating-td-column">{l.RatingCount[4]}</td>
							<td className="total-td-column">{l.AverageRating.toFixed(2)}</td>
						</tr>
					))}
				</tbody>
			);
		}
		return (
			<tbody className="loading-body">
				<div className="loading-div">
					Nothing yet...
				</div>
			</tbody>
		);
	}

	renderLeaderboardGrid = () => {
		const { leaderboard } = this.props;

		if (!leaderboard) {
			return (<div />);
		}
		return (
			<Table borderless striped bordered hover responsive className="leaderboard-table">
				<thead>
					<tr>
						<td className="number-td-column" />
						<td className="pic-td-column" />
						<td className="name-td-column" />
						<td className="rating-td-column">1</td>
						<td className="rating-td-column">2</td>
						<td className="rating-td-column">3</td>
						<td className="rating-td-column">4</td>
						<td className="total-tr-column">Score</td>
					</tr>
				</thead>
				{this.renderLeaderboardBody()}
			</Table>
		);
	}

	scoreTextColor = (expectationScore) => {
		let textColor = '#7AA05E';
		if (expectationScore < 1.7 || expectationScore > 2.5) {
			textColor = '#AF2419';
		} else if (expectationScore < 2.01 && expectationScore > 1.85) {
			textColor = '#3F5728';
		}
		return textColor;
	}

	renderHeader = () => {
		const { selectedMeeting, selectedDay } = this.props;
		if (selectedMeeting) {
			return (
				<Row className="leaderboard-header-row">
					<div>{selectedMeeting.Title} <span className="bold-title">SNAPSHOT</span></div>
					<div className="time-row">{moment(selectedMeeting.StartDatetime).format('ddd, D MMMM, YYYY')}</div>
				</Row>
			);
		} if (selectedDay.length > 0) {
			return (
				<Row className="leaderboard-header-row">
					<div><span className="bold-title">DAILY SNAPSHOT</span></div>
					<div className="time-row">{moment(selectedDay).format('ddd, D MMMM, YYYY')}</div>

				</Row>
			);
		}
	}

	render() {
		const { leaderboard, selectedMeeting, selectedDay } = this.props;

		if (!selectedMeeting && !(selectedDay.length > 0)) {
			return (
				<div />
			);
		}

		let totalFB = 0;
		let expecScore = 0;
		if (leaderboard) {
			// eslint-disable-next-line array-callback-return
			leaderboard.map((l) => {
				totalFB += l.ResponseCount;
				expecScore += (l.AverageRating * l.ResponseCount);
			});
			if (totalFB !== 0) {
				expecScore = (expecScore / totalFB).toFixed(2);
			}
		}

		return (
			<Modal
				size="md"
				show={this.props.showModal}
				onHide={this.props.lgClose}
				aria-labelledby="example-modal-sizes-title-lg"
			>
				<Container className="leaderboard-modal-container">
					{this.renderHeader()}
					<Row className="leaderboard-numbers-section">
						<Col className="participation-number">
							<div className="total-fb-number">{totalFB}</div>
							<div className="total-fb-title">TOTAL FEEDBACK</div>
						</Col>
						<Col className="expectation-number">
							<div className="total-expec-number" style={{ color: this.scoreTextColor(expecScore) }}>{expecScore}</div>
							<div className="total-expec-title">EXPECTATION SCORE</div>
						</Col>
					</Row>
					<Row className="leaderboard-section">
						<div className="bold-title-heading">LEADERBOARD</div>
						{selectedMeeting && selectedMeeting.IsLive && (
							<div className="happening-now">
								<div className="red-dot" />
								<div className="live-tag">HAPPENING NOW</div>
							</div>
						)}
					</Row>
					<Row className="leaderboard-stats-box">
						{this.renderLeaderboardGrid()}
					</Row>
				</Container>
			</Modal>
		);
	}
}

LeaderboardModal.propTypes = {
	leaderboard: PropTypes.array.isRequired,
	actions: PropTypes.object.isRequired,
	userActions: PropTypes.object.isRequired,
	selectedMeeting: PropTypes.object,
	selectedDay: PropTypes.string,
	showModal: PropTypes.bool.isRequired,
	lgClose: PropTypes.func.isRequired,
	self: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		leaderboard: state.feedback.leaderboard,
		self: state.user.self,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		userActions: bindActionCreators(userActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardModal);
