// orgId values
export const ORG_UUID_NXJ = '83b09ebc-d0c1-4dcd-9b03-73ae5a6b38cb';
export const ORG_UUID_NAVY = '2fa8f0e6-81e2-4f5b-80b0-2a5cfc9b4d80';

// org name
export const ORG_NAME_NXJ = 'Next Jump';
export const ORG_NAME_NAVY = 'US Navy';

export const ORG_NAME_PAW = 'Perks at Work';
export const ORG_NAME_WORLD = 'WORLD';
