import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as actions from '../../actions/feedbackActions';
import { reload } from '../../utils/reloader';
import images from '../../constants/images';

/* eslint no-console:off */

const DefaultPlaceholder = 'Please explain why this feedback is inappropriate.';
const ErrShortReason = 'Error: Your flagging reason is too short.';

const PostFlagSuccess = 'Your flag was processed successfully!';

class FlagModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			reasonValue: DefaultPlaceholder,
			error: '',

			reason: '',
			responseId: '',
		};
	}

	componentDidUpdate() {
		if (!this.props.selectedFeedback) return;
		if (this.state.responseId !== this.props.selectedFeedback.ResponseId) {
			// reset rating state
			this.reset();
		}
	}

	reset = () => {
		this.setState({
			error: '',
			reason: '',
			responseId: this.props.selectedFeedback.ResponseId,
		});
	}

	setReason = (event) => {
		const reason = event.target.value;

		// update comment value
		this.setState({ reason });
	}

	submit = () => {
		if (this.state.reason.length < 5) {
			console.warn(ErrShortReason);
			this.setState({ error: ErrShortReason });
			return;
		}

		this.props.actions.postFlag(this.state.responseId, this.state.reason).then((resp) => {
			if (typeof resp === 'string') {
				this.setState({ error: `API Error: ${resp}` });
				this.props.showFailureMsg(this.state.error);
				return;
			}

			// close modal
			// show message on user page
			this.reset();
			this.props.lgClose();
			this.props.showSuccessMsg(PostFlagSuccess);
			reload(['stream', 'meetings']);
		});
	}

	renderFlaggingDetails = () => (
		<Container className="flag-modal-container">
			<Row className="flag-title">
				<div className="flag-title-content">
					<img src={images.flagBigOn} className="flagIcon" alt="" />
					<span className="flag-title-text">Flag This Feedback</span>
				</div>
			</Row>
			<Row className="flag-description">
				Feedback app empowers the community to decide whether this feedback is appropriate or not.
			</Row>
			<Row className="flag-reason-box">
				<Container className="reason-box-container">
					<textarea className="reason-textarea" placeholder={this.state.reasonValue} value={this.state.reason} onChange={this.setReason} maxLength="500" />
				</Container>
			</Row>
			<Row className="flag-submit">
				<Container className="post-button-container">
					<div className="err-msg">{this.state.error}</div>
					<Button variant="primary" onClick={this.submit}>FLAG</Button>
				</Container>
			</Row>
		</Container>
	)

	render() {
		if (!this.props.selectedFeedback) {
			return null;
		}

		return (
			<Modal
				size="md"
				show={this.props.showModal}
				onHide={this.props.lgClose}
				aria-labelledby="example-modal-sizes-title-lg"
			>
				{this.renderFlaggingDetails()}
			</Modal>
		);
	}
}

FlagModal.propTypes = {
	actions: PropTypes.object.isRequired,

	selectedFeedback: PropTypes.object,
	showModal: PropTypes.bool.isRequired,
	showSuccessMsg: PropTypes.func.isRequired,
	showFailureMsg: PropTypes.func.isRequired,
	lgClose: PropTypes.func.isRequired,
};

function mapStateToProps() {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FlagModal);
