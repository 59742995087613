import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import images from '../constants/images';
import ProfileVoteButton from './ProfileVoteButton';

const getRatingColor = (rating) => {
	const map = {
		1: '#D65745',
		2: '#E79F3C',
		3: '#83BD63',
		4: '#4CA8D1',
	};

	return map[rating];
};

class ProfileSingleFeedCard extends Component {
	renderMeeting = () => {
		const { fb } = this.props;
		if ((fb.Tags) && (fb.Tags.length > 0) && (fb.Tags[0].Tag !== '')) {
			return (
				<span>{fb.Tags[0].Tag}</span>
			);
		}
	}

	render() {
		const { fb, index } = this.props;
		const ratingColor = getRatingColor(fb.Response.Rating);

		let fbDate = moment.utc(fb.Response.Created).format('MMMM DD, YYYY');
		if (moment().diff(moment.utc(fb.Response.Created), 'h') < 24) {
			fbDate = moment.utc(fb.Response.Created).fromNow();
			if (moment().diff(moment.utc(fb.Response.Created), 'h') >= 21) {
				fbDate = `${moment().diff(moment.utc(fb.Response.Created), 'h')} hours ago`;
			}
		}
		return (
			<Row className="profile-single-feed-box" style={{ borderLeft: `5px solid ${ratingColor}` }} key={fb.Response.ResponseId + index}>

				<Row className="profile-feed-content-row">
					<Col xs={1} className="rating-box">
						<div className="rating-score-circle" style={{ backgroundColor: ratingColor }}>{fb.Response.Rating}</div>
					</Col>
					<Col xs={11} className="comment-outer-box">
						<Row className="comment-box text-font text-black">
							{fb.Response.Comment}
						</Row>
					</Col>
				</Row>
				<Row className="profile-feed-time-row">
					<Col xs={8} className="time-meeting-box text-font text-light-grey">
						<img src={images.timeIcon} className="timeIcon" alt="" /><span>{fbDate}</span>&nbsp;&nbsp;&nbsp;
						<img src={images.meetingIcon} className={((!fb.Tags) || (fb.Tags.length === 0) || (fb.Tags[0].Tag === '')) ? 'meetingIcon displayNone' : 'meetingIcon'} alt="" />
						{this.renderMeeting()}
					</Col>
					<Col xs={4} className="voting-box">
						<ProfileVoteButton
							fb={fb}
							index={index}
							vote={this.props.vote}
							renderFlag={this.props.renderFlag}
						/>
					</Col>
				</Row>
			</Row>
		);
	}
}

ProfileSingleFeedCard.propTypes = {
	fb: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	vote: PropTypes.func.isRequired,
	renderFlag: PropTypes.func.isRequired,
};

export default ProfileSingleFeedCard;
