// this is the movie page to load the most popular movies
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import * as userActions from '../../actions/userActions';
import * as filterActions from '../../actions/filterActions';
import images from '../../constants/images';
import { reload } from '../../utils/reloader';

/* eslint no-console:off */

class FilterBar extends React.Component {
	searchBy = (event) => {
		const value = event.target.value;
		// trigger search only when the search string is greater than 2 characters
		if (value.length > 1) {
			this.props.userActions.loadUsers(0, true, value);
		}

		// reset signal
		if (value.length === 0) {
			this.props.userActions.loadUsers(0, true);
		}
	}

	renderRight = () => {
		let { timeIconBig, upvoteBig, downvoteBig, flagBig } = images;

		switch (this.props.filter.sort) {
		case 'UpVoteCount': // sort by upvote count descending
			upvoteBig = images.upvoteBigOn;
			break;
		case 'DownVoteCount': // sort by downvote count descending
			downvoteBig = images.downvoteBigOn;
			break;
		case 'InappropriateFlagCount': // sort by flag count descending
			flagBig = images.flagBigOn;
			break;
		default:
			timeIconBig = images.timeIconBigOn;
			break;
		}

		let right = (<div />);
		switch (this.props.right) {
		case 'search':
			right = (
				<Form>
					<Form.Control
						type="text"
						className="give-search-box"
						placeholder="Search Employee By Name"
						onChange={this.searchBy}
					/>
				</Form>
			);
			break;
		case 'sort':
			right = (
				<div className="sort-wrapper">
					<span>SORT BY</span>
					<img src={timeIconBig} className="sortIcons" onClick={() => this.sort('Latest')} alt="" />
					<img src={upvoteBig} className="sortIcons" onClick={() => this.sort('UpVoteCount')} alt="" />
					<img src={downvoteBig} className="sortIcons" onClick={() => this.sort('DownVoteCount')} alt="" />
					<img src={flagBig} className="sortIcons flagBigIcon" onClick={() => this.sort('InappropriateFlagCount')} alt="" />
				</div>
			);
			break;
		}

		return right;
	}

	renderSelfTeams = () => {
		let teams = [];
		let filterFound = false;
		if (this.props.self.Team && this.props.self.Team.length > 0) {
			teams = this.props.self.Team.map((value, index) => {
				if (index > 5) {
					return;
				}
				let activeNav = '';
				if (this.props.filter.team === value) {
					activeNav = 'active-nav';
					filterFound = true;
				}
				return (
					// eslint-disable-next-line react/no-array-index-key
					<Nav.Item key={index}>
						<Nav.Link className={activeNav} eventKey={value}>{value}</Nav.Link>
					</Nav.Item>
				);
			});
		}
		if (this.props.filter.team !== '' && !filterFound) {
			const newTeam = (
				<Nav.Item key="new-team-00">
					<Nav.Link className="active-nav" eventKey={this.props.filter.team}>{this.props.filter.team}</Nav.Link>
				</Nav.Item>
			);
			teams = [newTeam, ...teams];
		}
		return teams;
	}

	selectTeamHandler = (selected) => {
		// set the global filters
		this.props.filterActions.setGlobalFilters({ team: selected });
		if (selected === 'All') {
			this.props.filterActions.resetGlobalFilters(['team']);
		}

		reload();
	}

	sort = (value) => {
		this.props.filterActions.setGlobalFilters({ sort: value });
		if (value === 'Latest') {
			this.props.filterActions.resetGlobalFilters(['sort']);
		}

		reload(['stream']);
	}

	render() {
		const filter = this.props.filter;
		return (
			<Row className="filter-ribbon">
				<Col xs={7}>
					<Nav className="filter-ribbon-nav" onSelect={(selectedKey) => this.selectTeamHandler(selectedKey)}>
						<Nav.Item key={0}>
							<Nav.Link className={!filter.team || filter.team.length === 0 ? 'active-nav' : ''} eventKey="All">All</Nav.Link>
						</Nav.Item>
						{this.renderSelfTeams()}
					</Nav>
				</Col>
				<Col xs={5} className={`${this.props.right}-inner-box`}>
					{this.renderRight()}
				</Col>
			</Row>
		);
	}
}

FilterBar.propTypes = {
	self: PropTypes.object.isRequired,
	filter: PropTypes.object.isRequired,
	userActions: PropTypes.object.isRequired,
	filterActions: PropTypes.object.isRequired,

	right: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
	return {
		self: state.user.self,
		filter: state.filter,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		userActions: bindActionCreators(userActions, dispatch),
		filterActions: bindActionCreators(filterActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
