import { store } from '../store/configureStore';

const queryString = require('query-string');

const dispatch = store.dispatch;
const TimeZoneOffset = new Date().getTimezoneOffset() * 60;

/* global FEEDBACK_API */
/* eslint no-console:off */

const host = FEEDBACK_API;
const endpoints = {
	// get endpoints
	users: '/users',
	self: '/users/self',
	stream: '/stream',
	meetings: '/meetings',
	leaderboard: '/leaderboard',
	teams: '/team/filters',
	metrics: '/responses/metrics', // org metircs
	tags: '/alltags',
	recommendedTags: '/users/{}/suggestedtags',
	// post endpoints
	give: '/responses',
	vote: '/comment/thumb',
	flag: '/inappropriatefeedback/marked',
};
const limit = 50;

const fetch = (url, params) => ({
	type: 'FETCH',
	url,
	params,
});

const fbFilters = () => ({
	ResponseId: '',
	ResponseIds: [],
	RecipientId: '',
	UserId: '',
	TagId: '',
	Tag: '',
	StartDate: '', // time
	EndDate: '', // time
	Team: '',
	TimeZoneOffset,
});

const statsFilters = () => ({
	StartDate: '', // time
	EndDate: '', // time
});

const userFilters = () => ({
	fq: '',
	q: '',
});

const collectionParams = () => ({
	Offset: 0,
	Limit: limit,
	Sort: '',
	IsAscending: 0,
});

const fbPayload = () => ({
	Rating: 0,
	Comment: '',
	Tags: [],
	RecipientId: '',
});

const votePayload = () => ({
	ResponseId: '',
	ThumbValue: 0,
});

const flagPayload = () => ({
	ResponseId: '',
	Reason: 0,
});

class FeedbackApi {
	// api to get user list
	static getUsers(offset = 0, q = '', fq = '') {
		const params = { ...userFilters(), ...collectionParams() };
		Object.seal(params);

		params.Offset = offset;
		params.q = q;
		params.fq = fq;

		if (q.length > 2) {
			params.q = q;
		}

		const url = `${host + endpoints.users}?${queryString.stringify(params)}`;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	// api to get self info (user who logged in)
	static getSelf() {
		const url = host + endpoints.self;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	// api to get activity stream
	static getStream(offset = 0, lim = limit, filters = {}) {
		// combination of fbFilters and collectionParams
		const params = { ...fbFilters(), ...collectionParams() };
		Object.seal(params);

		params.Offset = offset;
		params.Limit = lim;

		const { team, sort, startDate, endDate, tagId } = filters;
		if (team) {
			params.Team = team;
		}
		if (sort) {
			params.Sort = sort;
		}
		if (startDate) {
			params.StartDate = startDate;
		}
		if (endDate) {
			params.EndDate = endDate;
		}
		if (tagId) {
			params.TagId = tagId;
		}

		const url = `${host + endpoints.stream}?${queryString.stringify(params)}`;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	// api to get list of meetings with filters
	static getMeetings(filters = {}) {
		const params = { ...fbFilters(), ...collectionParams() };
		Object.seal(params);

		if (filters.team && filters.team.length > 0) {
			params.Team = filters.team;
		}
		if (filters.startDate && filters.startDate.length > 0) {
			params.StartDate = filters.startDate;
		}
		if (filters.endDate && filters.endDate.lenght > 0) {
			params.EndDate = filters.endDate;
		}

		const url = `${host + endpoints.meetings}?${queryString.stringify(params)}`;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	// api to get leaderboard for meeting
	static getLeaderboard(tagId, startDate, endDate) {
		if (!tagId || tagId.length === 0) {
			const error = 'Invalid tagId';
			return error;
		}
		if (!startDate || !endDate) {
			const error = 'Invalid date';
			return error;
		}

		const params = { ...fbFilters(), ...collectionParams() };
		Object.seal(params);

		params.StartDate = startDate;
		params.EndDate = endDate;
		params.TagId = tagId;

		const url = `${host + endpoints.leaderboard}?${queryString.stringify(params)}`;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	// api to get leaderboard for a live meeting
	static getLiveLeaderboard(meetingId, startDate, endDate) {
		if (!meetingId || meetingId.length === 0) {
			const error = 'Invalid meeting';
			return error;
		}
		if (!startDate || !endDate) {
			const error = 'Invalid date';
			return error;
		}

		const params = { ...fbFilters(), ...collectionParams() };
		Object.seal(params);

		params.StartDate = startDate;
		params.EndDate = endDate;

		const url = `${host + endpoints.meetings}/${meetingId}/leaderboard?${queryString.stringify(params)}`;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	// api to get leaderboard for full day
	static getDayLeaderboard(startDate, endDate) {
		if (!startDate || !endDate) {
			const error = 'Invalid date';
			throw new Error(error);
		}

		const params = { ...fbFilters(), ...collectionParams() };
		Object.seal(params);

		params.StartDate = startDate;
		params.EndDate = endDate;

		const url = `${host + endpoints.leaderboard}?${queryString.stringify(params)}`;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => {
			console.warn(error);
		});
	}

	// api to get all the teams
	static getTeams(offset = 0, lim = limit) {
		const params = collectionParams();
		Object.seal(params);

		if (offset > 0) {
			params.Offset = offset;
		}
		params.Limit = lim;

		const url = `${host + endpoints.teams}?${queryString.stringify(params)}`;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	// api to get metrics for org
	// total # of feedback, expectaion score
	static getMetrics(start = '', end = '') {
		const params = fbFilters();
		Object.seal(params);
		params.StartDate = start;
		params.EndDate = end;

		const url = `${host + endpoints.metrics}?${queryString.stringify(params)}`;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	// api to get the recommended meetings tags for specific users
	static getRecommendedTags(userId) {
		const params = collectionParams();
		Object.seal(params);
		params.Limit = 5;

		const url = `${host + endpoints.recommendedTags.replace('{}', userId)}?${queryString.stringify(params)}`;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	// api to get all the meetings tags sorted by most used to least used
	static getTags() {
		const url = host + endpoints.tags;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	// api to get metrics for user
	// total # of feedback, expectaion score
	static getUserMetrics(start = '', end = '', userId) {
		const params = statsFilters();
		Object.seal(params);
		params.StartDate = start;
		params.EndDate = end;

		const url = `${host + endpoints.users}/${userId}/stats?${queryString.stringify(params)}`;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	static getUserFeedback(userId, offset = 0, lim = limit, filters = {}) {
		const params = { ...fbFilters(), ...collectionParams() };
		Object.seal(params);

		params.Offset = offset;
		params.Limit = lim;

		const { team, sort, startDate, endDate, tagId } = filters;
		if (team) {
			params.Team = team;
		}
		if (sort) {
			params.Sort = sort;
		}
		if (startDate) {
			params.StartDate = startDate;
		}
		if (endDate) {
			params.EndDate = endDate;
		}
		if (tagId) {
			params.TagId = tagId;
		}
		const url = `${host + endpoints.users}/${userId}/responses?${queryString.stringify(params)}`;

		return dispatch(fetch(url, { method: 'GET' })).then((response) => response.json()).catch((error) => error);
	}

	// api to post a new feedback
	static postFeedback(payload) {
		const pl = fbPayload();
		Object.seal(pl);

		const { rating, comment, tags, recipientId } = payload;
		if (rating && rating !== 0) {
			pl.Rating = rating;
		}
		if (comment && comment.length > 0) {
			pl.Comment = comment;
		}
		if (tags) {
			pl.Tags = tags;
		}
		if (recipientId && recipientId.length > 0) {
			pl.RecipientId = recipientId;
		}

		const url = host + endpoints.give;

		return dispatch(fetch(url, { method: 'POST', body: JSON.stringify(pl) })).then((response) => response.json()).catch((error) => error);
	}

	// api to post a new vote (up or down)
	static postVote(payload) {
		const pl = votePayload();
		Object.seal(pl);
		if (payload.responseId && payload.responseId.length > 0) {
			pl.ResponseId = payload.responseId;
		}

		pl.ThumbValue = payload.vote;

		const url = host + endpoints.vote;

		return dispatch(fetch(url, { method: 'POST', body: JSON.stringify(pl) })).then((response) => response.json()).catch((error) => error);
	}

	// api to flag feedback
	static postFlag(payload) {
		const pl = flagPayload();
		Object.seal(pl);
		if (payload.responseId && payload.responseId.length > 0) {
			pl.ResponseId = payload.responseId;
		}

		pl.Reason = payload.reason;

		const url = host + endpoints.flag;

		return dispatch(fetch(url, { method: 'POST', body: JSON.stringify(pl) })).then((response) => {
			if (response.status !== 200) {
				throw new Error('Failed to process flagging.');
			}
			return response.json();
		}).catch((error) => {
			console.warn(error);
			return 'Failed to process flagging.';
		});
	}
}

export default FeedbackApi;
