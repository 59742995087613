/* eslint-disable no-console */
// this is the reducer for all user related actions

import { produce } from 'immer';
import {
	LOAD_USERS_SUCCESS,
	RELOAD_USERS_SUCCESS,
	LOAD_SELF_METRICS_SUCCESS,
	LOAD_SELF_METRICS_FAILURE,
	LOAD_USER_METRICS_SUCCESS,
	LOAD_USER_METRICS_FAILURE,
	LOAD_USER_BY_ID_SUCCESS,
	LOAD_SELF_SUCCESS,
	LOAD_SELF_FEEDBACK_SUCCESS,
	LOAD_SELF_FEEDBACK_FAILURE,
	LOAD_USER_FEEDBACK_SUCCESS,
	LOAD_USER_FEEDBACK_FAILURE,
	SET_SELF_FILTERS_SUCCESS,
	RESET_SELF_FILTERS_ALL,
	RESET_SELF_FILTERS_PARTIAL,
	NO_SELF_FILTER_FOUND,
	SET_USER_FILTERS_SUCCESS,
	RESET_USER_FILTERS_ALL,
	RESET_USER_FILTERS_PARTIAL,
	NO_USER_FILTER_FOUND,
	SELECT_USER_SUCCESS,
	DESELECT_USER_SUCCESS,
} from '../constants/actionTypes';
import initialState from './initialState';

const userReducer = (state = initialState.user, action) =>
	produce(state, (draft) => {
		switch (action.type) {
		case LOAD_USERS_SUCCESS:
			draft.userCount = action.meta.totalCount;
			draft.users = [...draft.users, ...action.users];
			return;

		case RELOAD_USERS_SUCCESS:
			draft.userCount = action.meta.totalCount;
			if (action.users) {
				draft.users = action.users;
				draft.notFound = false;
			} else {
				draft.users = [];
				draft.notFound = true;
			}
			return;

		case LOAD_SELF_METRICS_SUCCESS: {
			const { OnesReceived, TwosReceived, ThreesReceived, FoursReceived } = action.metrics;
			const totalFeedback = OnesReceived + TwosReceived + ThreesReceived + FoursReceived;
			draft.metrics.totalFeedback = totalFeedback;

			if (totalFeedback > 0) {
				const avg
						= (OnesReceived * 1 + TwosReceived * 2 + ThreesReceived * 3 + FoursReceived * 4)
						/ totalFeedback;
				draft.metrics.expectationScore = avg;
			} else {
				draft.metrics.expectationScore = 0;
			}

			draft.metrics.rated1 = OnesReceived;
			draft.metrics.rated2 = TwosReceived;
			draft.metrics.rated3 = ThreesReceived;
			draft.metrics.rated4 = FoursReceived;

			return;
		}

		case LOAD_SELF_METRICS_FAILURE:
			console.warn('failed in loading self metrics');
			return;

		case LOAD_USER_METRICS_SUCCESS: {
			const { OnesReceived, TwosReceived, ThreesReceived, FoursReceived } = action.metrics;
			const totalFeedback = OnesReceived + TwosReceived + ThreesReceived + FoursReceived;
			draft.selectedUser.metrics.totalFeedback = totalFeedback;

			if (totalFeedback > 0) {
				const avg
						= (OnesReceived * 1 + TwosReceived * 2 + ThreesReceived * 3 + FoursReceived * 4)
						/ totalFeedback;
				draft.selectedUser.metrics.expectationScore = avg;
			} else {
				draft.selectedUser.metrics.expectationScore = 0;
			}

			draft.selectedUser.metrics.rated1 = OnesReceived;
			draft.selectedUser.metrics.rated2 = TwosReceived;
			draft.selectedUser.metrics.rated3 = ThreesReceived;
			draft.selectedUser.metrics.rated4 = FoursReceived;

			return;
		}

		case LOAD_USER_METRICS_FAILURE:
			console.warn('failed in loading user metrics');
			return;

		case LOAD_SELF_FEEDBACK_SUCCESS:
			draft.feedbackReceived.feedback = action.feedback;
			draft.feedbackCount = action.feedback.length;
			return;

		case LOAD_SELF_FEEDBACK_FAILURE:
			console.warn('failed in loading user feedback');
			draft.feedbackReceived.msg = "Sorry, we couldn't find your feedback! Please try again.";
			return;

		case LOAD_USER_FEEDBACK_SUCCESS:
			draft.selectedUser.feedbackReceived.feedback = action.feedback;
			draft.selectedUser.feedbackCount = action.feedback.length;
			return;

		case LOAD_USER_FEEDBACK_FAILURE:
			console.warn('failed in loading user feedback');
			draft.selectedUser.feedbackReceived.msg = "Sorry, we couldn't find any feedback! Please try again.";
			return;

		case SET_SELF_FILTERS_SUCCESS: {
			const { team, sort, startDate, endDate, tagId, tag } = action.userFilters;
			if (team) {
				draft.filter.team = team;
			}
			if (sort) {
				draft.filter.sort = sort;
			}

			if (startDate) {
				draft.filter.startDate = startDate;
			}

			if (endDate) {
				draft.filter.endDate = endDate;
			}

			if (tagId) {
				draft.filter.tagId = tagId;
				draft.filter.tag = tag;
			}
			return;
		}

		case RESET_SELF_FILTERS_ALL:
			draft.filter.team = '';
			draft.filter.sort = '';
			draft.filter.startDate = '';
			draft.filter.endDate = '';
			draft.filter.tagId = '';
			draft.filter.tag = '';
			return;

		case RESET_SELF_FILTERS_PARTIAL:
			if (action.toReset.includes('team')) {
				draft.filter.team = '';
			}

			if (action.toReset.includes('sort')) {
				draft.filter.sort = '';
				draft.filter.startDate = '';
				draft.filter.endDate = '';
			}

			if (action.toReset.includes('startDate')) {
				draft.filter.startDate = '';
			}

			if (action.toReset.includes('endDate')) {
				draft.filter.endDate = '';
			}

			if (action.toReset.includes('tagId')) {
				draft.filter.tagId = '';
				draft.filter.tag = '';
			}
			return;

		case NO_SELF_FILTER_FOUND:
			return;

		case SET_USER_FILTERS_SUCCESS: {
			const { team, sort, startDate, endDate, tagId, tag } = action.userFilters;
			if (team) {
				draft.selectedUser.filter.team = team;
			}
			if (sort) {
				draft.selectedUser.filter.sort = sort;
			}

			if (startDate) {
				draft.selectedUser.filter.startDate = startDate;
			}

			if (endDate) {
				draft.selectedUser.filter.endDate = endDate;
			}

			if (tagId) {
				draft.selectedUser.filter.tagId = tagId;
				draft.selectedUser.filter.tag = tag;
			}
			return;
		}

		case RESET_USER_FILTERS_ALL:
			draft.selectedUser.filter.team = '';
			draft.selectedUser.filter.sort = '';
			draft.selectedUser.filter.startDate = '';
			draft.selectedUser.filter.endDate = '';
			draft.selectedUser.filter.tagId = '';
			draft.selectedUser.filter.tag = '';
			return;

		case RESET_USER_FILTERS_PARTIAL:
			if (action.toReset.includes('team')) {
				draft.selectedUser.filter.team = '';
			}

			if (action.toReset.includes('sort')) {
				draft.selectedUser.filter.sort = '';
				draft.selectedUser.filter.startDate = '';
				draft.selectedUser.filter.endDate = '';
			}

			if (action.toReset.includes('startDate')) {
				draft.selectedUser.filter.startDate = '';
			}

			if (action.toReset.includes('endDate')) {
				draft.selectedUser.filter.endDate = '';
			}

			if (action.toReset.includes('tagId')) {
				draft.selectedUser.filter.tagId = '';
				draft.selectedUser.filter.tag = '';
			}
			return;

		case NO_USER_FILTER_FOUND:
			return;

		case SELECT_USER_SUCCESS:
			draft.selectedUser.user = action.user;
			return;

		case DESELECT_USER_SUCCESS:
			draft.selectedUser.user = {};
			draft.selectedUser.metrics = {
				totalFeedback: 0,
				expectationScore: 0,
				rated1: 0,
				rated2: 0,
				rated3: 0,
				rated4: 0,
			};
			draft.selectedUser.feedbackReceived = {
				feedback: [],
				msg: '',
			};
			draft.selectedUser.feedbackCount = 0;
			draft.selectedUser.filter = {
				team: '',
				sort: '',
				startDate: '',
				endDate: '',
				tagId: '',
				tag: '',
			};
			return;

		case LOAD_USER_BY_ID_SUCCESS:
			draft.user = action.user;
			return;

		case LOAD_SELF_SUCCESS:
			draft.self = action.self;
		}
	});

export default userReducer;
