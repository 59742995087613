// token middleware
// it allows you to apply the token everytime you call api
import { getToken, removeToken } from '../utils/localStorage';
import { logout, renewToken, validateToken } from '../utils/tokenHelper';
import history from '../store/history';

const jwtDecode = require('jwt-decode');

/* eslint no-console:off */

const authToken = (store) => (next) => (action) => {
	// skip if the action being dispatched is not FETCH
	if (action.type !== 'FETCH') {
		return next(action);
	}

	const { url, params } = action;
	const token = getToken();
	if (token) {
		validateToken(token).then((valid) => {
			if (!valid) {
				history.push('/login');
			}
		});

		const decoded = jwtDecode(token);
		const timestamp = Math.floor(Date.now() / 1000);

		// edge cases that the login session got stuck in a weird status 
		// where no claims inserted to ext claim in access_token.
		// force logout to get out of the session
		if (decoded.ext === undefined || decoded.ext.account_uuid === undefined) logout();

		if (decoded.exp < (timestamp + 600)) {
			console.log('renewing the token');
			removeToken();
			renewToken();
		}

		params.headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		};

		return fetch(url, params);
	}
	console.warn('no token in the storage, need to bounce to login page.', store.getState());
	history.push('/login');
};

export default authToken;
