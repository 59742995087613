// this is the movie page to load the most popular movies
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import * as userActions from '../../actions/userActions';
import images from '../../constants/images';
import { reload } from '../../utils/reloader';

/* eslint no-console:off */

class ProfileFilterBar extends React.Component {
	searchBy = (event) => {
		const value = event.target.value;
		// trigger search only when the search string is greater than 2 characters
		if (value.length > 1) {
			this.props.userActions.loadUsers(0, true, value);
		}

		// reset signal
		if (value.length === 0) {
			this.props.userActions.loadUsers(0, true);
		}
	}

	renderRight = () => {
		let { timeIconBig, upvoteBig, downvoteBig, flagBig } = images;

		switch (this.props.filter.sort) {
		case 'UpVoteCount': // sort by upvote count descending
			upvoteBig = images.upvoteBigOn;
			break;
		case 'DownVoteCount': // sort by downvote count descending
			downvoteBig = images.downvoteBigOn;
			break;
		case 'InappropriateFlagCount': // sort by flag count descending
			flagBig = images.flagBigOn;
			break;
		default:
			timeIconBig = images.timeIconBigOn;
			break;
		}

		let right = (<div />);
		switch (this.props.right) {
		case 'search':
			right = (
				<Form>
					<Form.Control
						type="text"
						className="give-search-box"
						placeholder="Search Employee By Name"
						onChange={this.searchBy}
					/>
				</Form>
			);
			break;
		case 'sort':
			right = (
				<div className="sort-wrapper">
					<span>SORT BY</span>
					<img src={timeIconBig} className="sortIcons" onClick={() => this.sort('Latest')} alt="" />
					<img src={upvoteBig} className="sortIcons" onClick={() => this.sort('UpVoteCount')} alt="" />
					<img src={downvoteBig} className="sortIcons" onClick={() => this.sort('DownVoteCount')} alt="" />
					<img src={flagBig} className="sortIcons flagBigIcon" onClick={() => this.sort('InappropriateFlagCount')} alt="" />
				</div>
			);
			break;
		}

		return right;
	}

	sort = (value) => {
		if (this.props.selectedUser) {
			this.props.userActions.setUserFilters({ sort: value }, this.props.user);
			if (value === 'Latest') {
				this.props.userActions.resetUserFilters(['sort']);
			}

			reload(['userFeedback']);
		} else {
			this.props.userActions.setUserFilters({ sort: value });
			if (value === 'Latest') {
				this.props.userActions.resetSelfFilters(['sort']);
			}

			reload(['selfFeedback']);
		}
	}

	render() {
		return (
			<Row className="filter-ribbon">
				<Col className={`${this.props.right}-inner-box`}>
					{this.renderRight()}
				</Col>
			</Row>
		);
	}
}

ProfileFilterBar.propTypes = {
	user: PropTypes.object,
	filter: PropTypes.object.isRequired,
	userActions: PropTypes.object.isRequired,

	right: PropTypes.string.isRequired,
	selectedUser: PropTypes.bool,
};

function mapStateToProps() {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		userActions: bindActionCreators(userActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFilterBar);
